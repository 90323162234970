import { useEffect, useState } from "react";
import SideNav from "./SideNav";
import Topbar from "./TopBar";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";

export default function Layout({ children }: { children: React.ReactNode }) {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isSmallerThan600] = useMediaQuery("(max-width: 779px)");

  useEffect(() => {
    if (isSmallerThan600) {
      setIsSidebarCollapsed(true);
    } else {
      setIsSidebarCollapsed(false);
    }
  }, [isSmallerThan600]);

  return (
    <Flex>
      <SideNav
        isCollapsed={isSidebarCollapsed}
        toggleSidebar={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        isMobile={isSmallerThan600}
      />
      <Flex
        flex="auto"
        direction="column"
        h="100vh"
        transition="margin-left 0.3s ease-in-out"
      >
        <Topbar isSidebarCollapsed={isSidebarCollapsed} />
        <Box
          bg="#F2F2F2"
          flex="1"
          h="calc(100% - 80px)"
          overflow="auto"
          p={{ base: 3, md: 10 }}
          maxWidth="100vw" // Prevent overflow
          display="flex" // Ensure flexible child alignment
          justifyContent="center" // Center content
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
}
