import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaCalendar, FaMapMarkedAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { PiPhoneFill } from "react-icons/pi";
import { TbUsersGroup } from "react-icons/tb";
import PreviewImageCard from "./PreviewImageCard";
import { RxDimensions } from "react-icons/rx";
import { FiFeather } from "react-icons/fi";
import { MdOutlineCategory } from "react-icons/md";

interface Ballpark {
  id: number;
  name: string;
  location: string;
  images: string[];
  fullAddress: string;
  capacity: string;
  group: string;
  mobileNumber: string;
  googleMapsLink: string;
  type: string;
  dateBuilt: string;
  dimensions: string;
  surfaceType: string;
}
interface viewinterface {
  ballparks: Ballpark[];
  active: number;
}

const ViewBallPark: React.FC<viewinterface> = ({ ballparks, active }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeBallpark, setactiveBallpark] = useState<Ballpark | null>();

  useEffect(() => {
    setActiveIndex(0);
    setactiveBallpark(ballparks.find((b: any) => b.id === active) || null);
  }, [active]);

  return (
    <Box
      background="#fff"
      p={{ base: 3, md: 8 }}
      rounded="2xl"
      className="ballpark-info"
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={6}
        alignItems="stretch"
      >
        <GridItem colSpan={1} height={"100%"}>
          <Text fontSize="xl" fontWeight="semibold">
            {activeBallpark?.name}
          </Text>
          <Box
            display={"flex"}
            flexDirection="column"
            justifyContent="space-between"
            h="93%"
            mt={3}
          >
            <Stack color="custom.grayIcon">
              <Flex gap={2} alignItems="center">
                <FaMapMarkedAlt size="20px" color="#545560" />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.location}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <FaCalendar size="20px" color="#545560" />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  Built in {activeBallpark?.dateBuilt}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <RxDimensions size="20px" color="#545560" />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.dimensions}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <FiFeather size="20px" color="#545560" />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.surfaceType}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <MdOutlineCategory size="20px" color="#545560" />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.type}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <img
                  src="./images/ballparks/group-community-social-media.png"
                  alt="Media Group"
                  height="20px"
                  width="20px"
                />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.group}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <PiPhoneFill size="20px" color="#545560" />

                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.mobileNumber}
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <TbUsersGroup size="20px" color="#545560" />
                <Text fontSize="normal" fontWeight="500" color="custom.gray3">
                  {activeBallpark?.capacity} Capacity
                </Text>
              </Flex>

              <Flex gap={2} alignItems="center">
                <FaLocationDot size="20px" color="#545560" />
                <Link
                  href={activeBallpark?.googleMapsLink}
                  isExternal
                  color="blue"
                  fontSize="md"
                  fontWeight={500}
                >
                  {activeBallpark?.fullAddress}
                </Link>
              </Flex>
            </Stack>
            <Box position="relative" h={120} mb={4}>
              <Flex
                gap={4}
                overflowX="auto"
                whiteSpace="nowrap"
                w={"100%"}
                position={"absolute"}
                top={0}
                flexShrink={0}
              >
                {activeBallpark?.images.map((img, index) => (
                  <Box
                    _hover={{
                      cursor: "pointer",
                    }}
                    key={index}
                    flexShrink={0}
                    onClick={() => setActiveIndex(index)}
                    h={120}
                    maxW={200}
                    position="relative"
                  >
                    {" "}
                    <PreviewImageCard src={img} />
                  </Box>
                ))}
              </Flex>
            </Box>
          </Box>
        </GridItem>

        <GridItem colSpan={1} width="100%">
          <Box display="flex">
            <Image
              src={activeBallpark?.images[activeIndex]}
              alt="Stadium"
              w={"100%"}
              maxH={400}
              sx={{
                aspectRatio: "16 / 9",
              }}
              objectFit="cover"
              borderRadius="xl"
            />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ViewBallPark;
