import axios from "axios";

const baseURL =
  `${process.env.REACT_APP_BASE_URL||"http://localhost:3000"}/api`;

const axiosInstance = axios.create({
  baseURL: baseURL,
  // other axios configurations
});

export default axiosInstance;
