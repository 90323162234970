import React, { useState, useEffect } from 'react';

export interface EditFormValues {  // <-- Agrega 'export' aquí
  firstName: string;
  initial: string;
  maidenName: string;
  lastName: string;
  dateOfBirth: string;
  playingPositions: string[];
  uniformNumber: string;
  city: string;
  state: string;
  country: string;
  graduationYear: string;
  graduationYearOption: string;
  currentSchool: string;
  aimingColleges: string[];
  last4SN: string;
  weight: string;
  height: string;
  throwingHand: string;
  battingHand: string;
  legalWaiver: boolean;
  selectedPlan: string;
}

import { useLocation, useNavigate } from 'react-router-dom';
import { EditPlayerFormProvider } from '../context/EditPlayerFormContext';
import UpgradePlayerStep1 from './UpgradePlayerStep1';
import UpgradePlayerStep2 from './UpgradePlayerStep2';
import UpgradePlayerStep3 from './UpgradePlayerStep3';
import {
  Box,
  Container,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
  useBreakpointValue,
  Flex,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';


function formatDateForInput(dateString: string): string {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return '';
  }
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

const UpgradePlayerWizard: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  const playerData = location.state?.player;

  useEffect(() => {
    if (!playerData) {
      console.error('No player data found. Redirecting to My Roster.');
      navigate('/my-roster');
    }
  }, [playerData, navigate]);

  // Función para transformar playerData
  const transformPlayerData = (data: any): Partial<EditFormValues> => {
    return {
      firstName: data.FIRST_NAME || '',
      initial: data.INITIAL || '',
      maidenName: data.MAIDEN_NAME || '',
      lastName: data.LAST_NAME || '',
      dateOfBirth: data.DATE_OF_BIRTH ? formatDateForInput(data.DATE_OF_BIRTH) : '',
      playingPositions: data.PLAYING_POSITIONS
        ? data.PLAYING_POSITIONS.split(', ').map((pos: string) => pos.trim())
        : [],
      uniformNumber: data.UNIFORM_NUMBER || '',
      city: data.CITY || '',
      state: data.STATE || '',
      country: data.COUNTRY || '',
      graduationYear: data.GRADUATION_YEAR || '',
      graduationYearOption: data.GRADUATION_YEAR_OPTION || '',
      last4SN: data.LAST4_SN || '',
      currentSchool: data.CURRENT_SCHOOL || '',
      aimingColleges: data.AIMING_COLLEGES
        ? data.AIMING_COLLEGES.split(', ').map((college: string) => college.trim())
        : [],
      weight: data.WEIGHT || '',
      height: data.HEIGHT || '',
      throwingHand: data.THROWING_HAND || '',
      battingHand: data.BATTING_HAND || '',
      legalWaiver: false,
      selectedPlan: '',
    };
  };
  
  const transformedPlayerData = transformPlayerData(playerData);

  const steps = [
    { title: 'New Player' },
    { title: 'Add-Ons' },
    { title: 'Review' },
  ];

  const stepperOrientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  });

  const handleNextStep = () => setActiveStep((prev) => prev + 1);
  const handlePreviousStep = () => setActiveStep((prev) => prev - 1);

  return (
    <EditPlayerFormProvider initialData={transformedPlayerData}>
      <Box width="100%" p={2} minHeight="100vh" mx="auto">
        <Container maxW="container.xl" mx="auto" px={0}>
          <Flex mb={6} direction="row" align="center" justify="space-between">
            <Flex align="center" width="250px">
              {activeStep === 1 && (
                <IconButton
                  aria-label="Back to My Roster"
                  icon={<FaArrowLeft />}
                  onClick={() => navigate('/my-roster')}
                  bg="white"
                  boxShadow="sm"
                  _hover={{ boxShadow: 'md', bg: 'gray.200' }}
                  mr={2}
                />
              )}
              <Box>
                <Text fontSize="3xl" fontWeight="bold">
                  {steps[activeStep - 1].title}
                </Text>
              </Box>
            </Flex>
            <Box flex="1">
              <Stepper
                index={activeStep - 1}
                orientation={stepperOrientation}
                colorScheme="blue"
                width="80%"
                mx="auto"
                gap={4}
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator
                      boxSize={12}
                      _active={{ bg: 'blue.500', color: 'white' }}
                      _complete={{ bg: 'blue.500', color: 'white' }}
                      _incomplete={{ bg: 'gray.200', color: 'gray.500' }}
                    >
                      {index + 1}
                    </StepIndicator>
                    <Box flexShrink="0">
                      <StepStatus
                        complete={<StepTitle>{step.title}</StepTitle>}
                        incomplete={<StepTitle>{step.title}</StepTitle>}
                        active={<StepTitle>{step.title}</StepTitle>}
                      />
                    </Box>
                    {index !== steps.length - 1 && <StepSeparator />}
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Flex>
          <Box>
            {activeStep === 1 && <UpgradePlayerStep1 handleNextStep={handleNextStep} />}
            {activeStep === 2 && (
              <UpgradePlayerStep2
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
              />
            )}
            {activeStep === 3 && <UpgradePlayerStep3 handlePreviousStep={handlePreviousStep} />}
          </Box>
        </Container>
      </Box>
    </EditPlayerFormProvider>
  );
};

export default UpgradePlayerWizard;
