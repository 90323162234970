// src/components/players_registry/RegisterSuccessModal.tsx
import { Box, Button, CloseButton, Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'

interface SuccessProps {
    closeSuccess: () => void;
    isSuccessOpen: boolean;
    actionType: string;
}

const RegisterSuccessModal: React.FC<SuccessProps> = ({ closeSuccess, isSuccessOpen, actionType }) => {
    return (
        <Modal onClose={closeSuccess} isOpen={isSuccessOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalBody position={"relative"} paddingTop={6} px={{ base: 4, md: 12 }}>
                    <Box position="absolute" top={4} right={4}>
                        <CloseButton onClick={closeSuccess} />
                    </Box>
                    <Flex direction="column" alignItems="center" textAlign="center" justifyContent={"center"} mb={4}>
                        <Image
                            alt="Illustration of a baseball player with a bat and helmet"
                            boxSize="80px"
                            src="/images/Success.png"
                        />
                        <Text
                            py={4}
                            fontSize='xl'
                            fontWeight="bold">
                            Success
                        </Text>
                        <Text fontWeight="bold">User {actionType == 'create' ? 'Created' : "Updated"} Susscessfully</Text>
                    </Flex>
                </ModalBody>
                <ModalFooter display={'flex'} justifyContent={'center'} gap={'4'} px={'30px'}>
                    <Button
                        bgColor={'#1B154B'}
                        variant={'solid'}
                        colorScheme='darkpurple'
                        color={'white'}
                        h={12}
                        onClick={closeSuccess}
                        _hover={{ bg: '#1B154B', opacity: 0.8 }}
                        w='150px'> Ok </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
}

export default RegisterSuccessModal