// stats-ui/src/components/players_registry/RegisterErrorModal.tsx
import { Box, Button, CloseButton, Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'

interface ErrorProps {
    closeError: () => void;
    isErrorOpen: boolean;
}

const RegisterErrorModal: React.FC<ErrorProps> = ({ closeError, isErrorOpen }) => {
    return (
        <Modal onClose={closeError} isOpen={isErrorOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalBody position={"relative"} paddingTop={6} px={{ base: 4, md: 12 }}>
                    <Box position="absolute" top={4} right={4}>
                        <CloseButton onClick={closeError} />
                    </Box>
                    <Flex direction="column" alignItems="center" textAlign="center" justifyContent={"center"} mb={4}>

                        <Image
                            alt="Illustration of a baseball player with a bat and helmet"
                            boxSize="80px"
                            src="/images/Danger.png"
                        />
                        <Text
                            py={4}
                            fontSize='xl'
                            fontWeight="bold">
                            Denied Request
                        </Text>
                        <Text fontWeight="bold">Conflict user already created</Text>
                    </Flex>
                </ModalBody>
                <ModalFooter display={'flex'} justifyContent={'center'} gap={'4'} px={'30px'}>
                    <Button
                        bgColor={'#1B154B'}
                        variant={'solid'}
                        colorScheme='darkpurple'
                        color={'white'}
                        h={12}
                        onClick={closeError}
                        _hover={{ bg: '#1B154B', opacity: 0.8 }}
                        w='150px'> Ok </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default RegisterErrorModal
