// src/components/PlayerCard.tsx
import React, { memo } from 'react';
import {
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FaEllipsisH,
  FaTimes,
  FaEdit,
  FaShareAlt,
  FaRedoAlt,
  FaChartBar,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import VintageCard from './VintageCard';
import ModernCard from './ModernCard';
import WaifModernCard from './WaifModernCard';
import WaifVintageCard from './WaifVintageCard';

interface Player {
  FIRST_NAME: string;
  LAST_NAME: string;
  PLAYING_POSITIONS: string;
  DATE_OF_BIRTH: string;
  UNIFORM_NUMBER: string;
  PLAYER_ID: string;
  STATUS: string;
}

interface PlayerCardProps {
  player: Player;
  isModern: boolean;
}

const PlayerCard: React.FC<PlayerCardProps> = ({ player, isModern }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg="white"
      p={2}
      borderRadius="xl"
      boxShadow="lg"
      border="1px solid #D0D0E1"
      height="472px"
      width="100%"
      maxWidth="353px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {player.STATUS === 'Waif' ? (
        isModern ? (
          <WaifModernCard
            playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
            playerPositions={player.PLAYING_POSITIONS.split(', ').join(', ')}
            playerID={player.PLAYER_ID}
          />
        ) : (
          <WaifVintageCard
            playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
            playerPositions={player.PLAYING_POSITIONS.split(', ').join(', ')}
            playerID={player.PLAYER_ID}
            uniformNumber={player.UNIFORM_NUMBER}
          />
        )
      ) : isModern ? (
        <ModernCard
          playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
          playerPositions={player.PLAYING_POSITIONS.split(', ').join(', ')}
          playerID={player.PLAYER_ID}
        />
      ) : (
        <VintageCard
          playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
          playerPositions={player.PLAYING_POSITIONS.split(', ').join(', ')}
          playerID={player.PLAYER_ID}
          uniformNumber={player.UNIFORM_NUMBER}
        />
      )}
      <Popover isOpen={isOpen} onClose={onClose} placement="top-end">
        <PopoverTrigger>
          <IconButton
            icon={isOpen ? <FaTimes /> : <FaEllipsisH />}
            aria-label="Options"
            borderRadius="full"
            color="white"
            backgroundColor="#1B154B"
            position="absolute"
            bottom="15px"
            right="16px"
            _hover={{ backgroundColor: 'blue.800' }}
            w="42px"
            h="42px"
            fontSize="20px"
            zIndex={60}
            onClick={isOpen ? onClose : onOpen}
          />
        </PopoverTrigger>
        <PopoverContent
          width="auto"
          bg="transparent"
          boxShadow="none"
          _focus={{ boxShadow: 'none' }}
        >
          <VStack
            spacing={2}
            alignItems="center"
            position="absolute"
            bottom="3px"
            right="1px"
          >
            {player.STATUS === 'Waif' ? (
              <IconButton
                icon={<FaEdit />}
                aria-label="Edit Player"
                borderRadius="full"
                color="#1B154B"
                backgroundColor="white"
                border="1px solid #1B154B"
                w="36px"
                h="36px"
                fontSize="15px"
                _hover={{ backgroundColor: '#E2E8F0' }}
                onClick={() =>
                  navigate(`/upgrade-player/${player.PLAYER_ID}`, { state: { player } })
                }
              />
            ) : (
              <>
                <IconButton
                  icon={<FaShareAlt />}
                  aria-label="Share"
                  borderRadius="full"
                  color="#1B154B"
                  backgroundColor="white"
                  border="1px solid #1B154B"
                  w="36px"
                  h="36px"
                  fontSize="15px"
                  _hover={{ backgroundColor: '#E2E8F0' }}
                />
                <IconButton
                  icon={<FaRedoAlt />}
                  aria-label="Rotate Card"
                  borderRadius="full"
                  color="#1B154B"
                  backgroundColor="white"
                  border="1px solid #1B154B"
                  w="36px"
                  h="36px"
                  fontSize="15px"
                  _hover={{ backgroundColor: '#E2E8F0' }}
                />
                <IconButton
                  icon={<FaChartBar />}
                  aria-label="Statistics"
                  borderRadius="full"
                  color="#1B154B"
                  backgroundColor="white"
                  border="1px solid #1B154B"
                  w="36px"
                  h="36px"
                  fontSize="15px"
                  _hover={{ backgroundColor: '#E2E8F0' }}
                />
              </>
            )}
          </VStack>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default memo(PlayerCard);
