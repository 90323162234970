import { Box } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "swiper/css/bundle";
import "swiper/css/grid";
import "swiper/css";
import BallParkCard from "./BallParkCard";
import { Grid, Navigation } from "swiper/modules";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

interface BallParkSliderinterface {
  ballparks: {
    id: number;
    name: string;
    location: string;
    images: string[];
    fullAddress: string;
    capacity: string;
    group: string;
    mobileNumber: string;
    googleMapsLink: string;
    type: string;
  }[];
  handleActive: (index: number) => void;
}
const BallParkSlider: React.FC<BallParkSliderinterface> = ({
  ballparks,
  handleActive,
}) => {
  return (
    <Box mt={6} className="ballparks-slider" h={"100%"}>
      <Swiper
        observer={true}
        observeParents={true}
        spaceBetween={10}
        slidesPerView={5}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="mySwiper"
        modules={[Grid, Navigation]}
        navigation={{
          prevEl: ".ballpark-swiper-prev",
          nextEl: ".ballpark-swiper-next",
        }}
        grid={{
          rows: 2, // Specify the number of rows
          // fill: "row", // Change fill direction to column
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1490: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1600: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
      >
        {ballparks?.map((ballpark, index) => {
          return (
            <SwiperSlide key={index} onClick={() => handleActive(ballpark.id)}>
              <BallParkCard ballpark={ballpark} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="ballpark-swiper-prev">
        <MdKeyboardArrowLeft />
      </div>
      <div className="ballpark-swiper-next">
        <MdKeyboardArrowRight />
      </div>
    </Box>
  );
};

export default BallParkSlider;
