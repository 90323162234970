import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface UserState {
  loading: boolean;
  success: boolean;
  error: string | null;
}

const initialState: UserState = {
  loading: false,
  success: false,
  error: null,
};

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/users`;

// Async thunk for creating a Cognito user
export const createCognitoUser = createAsyncThunk(
  "user/createCognitoUser",
  async (
    userData: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zipCode: string;
      dateOfBirth: string;
      email: string;
      firstName: string;
      lastName: string;
      initial: string;
      telephoneNumber: string;
      gender: string;
      role: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/create-user`, userData);
      return response.data; // This will be passed to the `fulfilled` case
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create user"
      );
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCognitoUser.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(createCognitoUser.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(
        createCognitoUser.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.success = false;
          state.error = action.payload || "Something went wrong";
        }
      );
  },
});

export const { resetState } = userSlice.actions;

export default userSlice.reducer;
