// src/components/MyRoster.tsx
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Grid,
  Center,
  Spinner,
  Text,
  Badge,
  Heading,
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  Button,
  FormControl,
  FormLabel,
  Switch,
  useToast,
} from '@chakra-ui/react';
import {
  FaSearch,
  FaShoppingBag,
  FaChevronLeft,
  FaUserPlus,
} from 'react-icons/fa';
import axiosInstance from '../api/axiosConfig';
import { useNavigate } from 'react-router-dom';
import BuySpotsModal from '../components/players_registry/BuySpotModal';
import PlayerCard from '../components/players_registry/PlayerCard'; // Importar el nuevo componente

interface Player {
  FIRST_NAME: string;
  LAST_NAME: string;
  PLAYING_POSITIONS: string;
  DATE_OF_BIRTH: string;
  UNIFORM_NUMBER: string;
  PLAYER_ID: string;
  STATUS: string;
}

interface Guardian {
  EMAIL: string;
  AVAILABLE_SPOTS: number;
  // Otros campos si los hay
}

const MyRoster: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  // Estado para controlar el tipo de tarjeta
  const [isModern, setIsModern] = useState(false);

  // Recuperar guardianEmail desde localStorage
  const storedGuardian = useMemo(() => {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }, []);
  const guardianEmail = storedGuardian?.email;

  // Estados para spots
  const [availableSpots, setAvailableSpots] = useState(0); // Inicializar en 0
  const [maxSpots, setMaxSpots] = useState(0); // Calcular después de obtener jugadores y guardian

  // Contadores optimizados con useMemo
  const waifCount = useMemo(
    () => players.filter((player) => player.STATUS === 'Waif').length,
    [players]
  );
  const officialCount = useMemo(
    () => players.filter((player) => player.STATUS === 'Official').length,
    [players]
  );

  // Fetch de jugadores por guardianEmail
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        if (!guardianEmail) {
          console.error('No guardian email found in local storage.');
          setLoading(false);
          return;
        }

        const response = await axiosInstance.get<{ success: boolean; players: Player[] }>('/players/by-guardian', {
          params: { guardianEmail },
        });

        if (response.data.success) {
          setPlayers(response.data.players);
        } else {
          console.error('Error in response:', response.data);
          toast({
            title: 'Error',
            description: 'No se pudo obtener la lista de jugadores.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error fetching players:', error);
        toast({
          title: 'Error',
          description: 'Ocurrió un error al obtener los jugadores.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const fetchGuardian = async () => {
      try {
        if (!guardianEmail) {
          console.error('No guardian email found in local storage.');
          return;
        }

        const response = await axiosInstance.get<{ success: boolean; guardian: Guardian }>(`/guardian/${guardianEmail}`);

        if (response.data.success) {
          setAvailableSpots(response.data.guardian.AVAILABLE_SPOTS);
        } else {
          console.error('Error in response:', response.data);
          toast({
            title: 'Error',
            description: 'No se pudo obtener la información del guardian.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error fetching guardian:', error);
        toast({
          title: 'Error',
          description: 'Ocurrió un error al obtener la información del guardian.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchPlayers(), fetchGuardian()]);
      setLoading(false);
    };

    fetchData();
  }, [guardianEmail, toast]);

  // Calcular maxSpots después de obtener jugadores y guardian
  useEffect(() => {
    setMaxSpots(99 - waifCount - officialCount - availableSpots);
  }, [waifCount, officialCount, availableSpots]);

  // Filtrado de jugadores basado en el término de búsqueda
  const filteredPlayers = useMemo(() => {
    const lowercasedTerm = searchTerm.trim().toLowerCase();
    return players.filter((player) => {
      const fullName = `${player.FIRST_NAME} ${player.LAST_NAME}`.toLowerCase();
      const positions = player.PLAYING_POSITIONS.toLowerCase();
      const playerID = player.PLAYER_ID.toLowerCase();
      const uniformNumber = player.UNIFORM_NUMBER.toLowerCase();
      return (
        fullName.includes(lowercasedTerm) ||
        positions.includes(lowercasedTerm) ||
        playerID.includes(lowercasedTerm) ||
        uniformNumber.includes(lowercasedTerm)
      );
    });
  }, [players, searchTerm]);

  const [isBuySpotsModalOpen, setBuySpotsModalOpen] = useState(false);
  const openBuySpotsModal = () => setBuySpotsModalOpen(true);
  const closeBuySpotsModal = () => setBuySpotsModalOpen(false);

  // Función para manejar la actualización de AVAILABLE_SPOTS después de la compra
  const handleSpotsPurchased = useCallback((newAvailableSpots: number) => {
    setAvailableSpots(newAvailableSpots);
  }, []);

  return (
    <>
      <Box width="100%" maxWidth="1125px" mx="auto" p={2} minHeight="100vh">
        <Flex gap={2} alignItems="center" mb={{ base: 3, md: 7 }}>
          <Button
            background="#fff"
            onClick={() => navigate('/players')}
            width="40px"
          >
            <FaChevronLeft fontSize="1.5em" />
          </Button>
          <Heading
            as="h3"
            fontSize={{ base: '18px', md: '24px', lg: '30px', xl: '3xl' }}
          >
            My Roster
          </Heading>
        </Flex>

        {/* Barra de búsqueda, contadores, switch y botón */}
        <Grid
          templateColumns={{
            base: '1fr',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(4, 1fr)', // Ajustar a 4 columnas para incluir el switch
          }}
          gap={{ base: 4, md: 6 }}
          justifyItems="center"
          justifyContent="center"
          mt={6}
          width="100%" // Cambiar a 100% para mejor responsividad
          mx="auto"
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search players"
              bg="white"
              border="1px solid gray"
              borderRadius="md"
              width={{ base: '100%', md: '353px' }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>

          <Center>
            <Flex>
              <Box
                border="1px solid gray"
                px={6}
                py={2}
                width="131px"
                color="#1A365D"
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderTopLeftRadius="md"
                borderBottomLeftRadius="md"
                borderRight="none"
              >
                Available
                <Badge ml={2} borderRadius="full" px={2} bg="#1B154B" color="white">
                  {availableSpots}
                </Badge>
              </Box>
              <Box
                border="1px solid gray"
                px={6}
                py={2}
                width="100px"
                color="#1A365D"
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderLeft="none"
                borderRight="none"
              >
                Waif
                <Badge ml={2} borderRadius="full" px={2} bg="#1B154B" color="white">
                  {waifCount}
                </Badge>
              </Box>
              <Box
                border="1px solid gray"
                px={6}
                py={2}
                width="122px"
                color="#1A365D"
                bg="white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderTopRightRadius="md"
                borderBottomRightRadius="md"
                borderLeft="none"
              >
                Official
                <Badge ml={2} borderRadius="full" px={2} bg="#1B154B" color="white">
                  {officialCount}
                </Badge>
              </Box>
            </Flex>
          </Center>

          {/* Agregar el Switch para cambiar el tipo de tarjeta */}
          <FormControl display="flex" alignItems="center" justifyContent="center">
            <FormLabel htmlFor="card-type-switch" mb="1">
              Modern
            </FormLabel>
            <Switch
              id="card-type-switch"
              isChecked={isModern}
              mb="1"
              onChange={(e) => setIsModern(e.target.checked)}
              colorScheme="blue"
              sx={{
                '& .chakra-switch__track': {
                  bg: '#CFB095',
                  transition: 'background-color 0.2s',
                  _checked: {
                    bg: 'blue.500',
                  },
                },
              }}
            />
          </FormControl>

          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button
              bg="#1B154B"
              color="gray.100"
              border="none"
              leftIcon={<FaShoppingBag />}
              _hover={{ bg: '#1B154B', opacity: 0.8 }}
              onClick={openBuySpotsModal}
              width="190px"
              height={12}
            >
              Buy Spots
            </Button>
          </Box>
        </Grid>

        {loading ? (
          <Center mt={10}>
            <Spinner size="xl" color="blue.500" />
          </Center>
        ) : (
          <Box display="flex" justifyContent="center" width="100%" mt="-18px" p={2}>
            <Grid
              templateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
              }}
              gap={{ base: 4, md: 6 }}
              justifyItems="center"
              justifyContent="center"
              mt={6}
              width="100%" // Cambiar a 100% para mejor responsividad
              mx="auto"
            >
              {filteredPlayers.map((player) => (
                <PlayerCard key={player.PLAYER_ID} player={player} isModern={isModern} />
              ))}
              {availableSpots > 0 &&
                Array.from({ length: availableSpots }).map((_, index) => (
                  <Box
                    key={`add-player-${index}`}
                    bg="white"
                    p={2}
                    borderRadius="md"
                    boxShadow="lg"
                    border="1px solid gray"
                    height="477px"
                    width="100%" // Ajustar ancho para responsividad
                    maxWidth="353px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    >
                    <Box
                      onClick={() => navigate('/create-player')}
                      bg="#1B154B"
                      _hover={{ bg: '#1B154B', opacity: 0.8 }}
                      cursor="pointer"
                      color="white"
                      height="220px"
                      width="220px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="md"
                    >
                      <FaUserPlus size="80px" />
                      <Text fontSize="lg" mt={4} textAlign="center">
                        Add Players
                      </Text>
                    </Box>
                  </Box>
                ))}
            </Grid>
          </Box>
        )}
        <BuySpotsModal
          isOpen={isBuySpotsModalOpen}
          onClose={closeBuySpotsModal}
          maxSpots={maxSpots}
          onSpotsPurchased={handleSpotsPurchased} // Pasar la función de callback
        />
      </Box>
    </>
  );
};

export default MyRoster;
