// src/components/players_registry/BuySpotModal.tsx
import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  Flex,
  IconButton,
  Icon,
  useBreakpointValue,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepSeparator,
  StepTitle,
  Divider,
  Image,
  useToast,
} from '@chakra-ui/react';
import { FaCaretUp, FaCaretDown, FaApple, FaGoogle, FaCheckCircle } from 'react-icons/fa';
import WizardButton from '../Shared/WizardButton';
import axiosInstance from '../../api/axiosConfig';

interface BuySpotsModalProps {
  isOpen: boolean;
  onClose: () => void;
  maxSpots: number; // Número máximo de spots disponibles para comprar
  onSpotsPurchased: (newAvailableSpots: number) => void; // Callback para actualizar los spots en el frontend
}

const BuySpotsModal: React.FC<BuySpotsModalProps> = ({ isOpen, onClose, maxSpots, onSpotsPurchased }) => {
  const [step, setStep] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const toast = useToast();

  const stepTitles = ['Spots', 'Payment', 'Review'];
  const minQuantity = 1;

  // Recuperar guardianEmail desde localStorage
  const storedGuardian = JSON.parse(localStorage.getItem('user') || '{}');
  const guardianEmail = storedGuardian?.email;

  useEffect(() => {
    if (isOpen) {
      setStep(1);
      setQuantity(1);
      setIsConfirmOpen(false);
    }
  }, [isOpen]);

  const stepperOrientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  });

  const handleNext = () => {
    if (step < stepTitles.length) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const changeQuantity = (change: number) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      return Math.max(minQuantity, Math.min(newQuantity, maxSpots));
    });
  };

  const handleMouseDown = (change: number) => {
    changeQuantity(change); // Primer cambio inmediato
    const id = setInterval(() => changeQuantity(change), 100); // Continuar incrementando/decrementando cada 100ms
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleBuy = async () => {
    try {
      // Validar guardianEmail
      if (!guardianEmail) {
        toast({
          title: 'Error',
          description: 'Guardian email not found.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Realizar la llamada a la API para comprar spots
      const response = await axiosInstance.post('/guardian/buy-spots', {
        guardianEmail,
        spotsToBuy: quantity,
      });

      if (response.data.success) {
        setIsConfirmOpen(true);
        onSpotsPurchased(response.data.availableSpots); // Actualizar los spots en el frontend
      } else {
        toast({
          title: 'Purchase Failed',
          description: response.data.message || 'Could not complete the purchase.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error comprando spots:', error);
      toast({
        title: 'Failed to Purchase Spots',
        description: 'An error occurred while attempting to buy the spots.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const closeConfirmModal = () => {
    setIsConfirmOpen(false);
    onClose();
    // Opcional: Puedes refrescar los datos aquí si es necesario
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent width="580px" maxW="90%" maxH="90%" height="400px" px={6}>
          <ModalHeader>
            <Flex justify="center" mb={4}>
              <Stepper
                index={step - 1}
                orientation={stepperOrientation}
                colorScheme="blue"
                width="100%"
                height="70px"
              >
                {stepTitles.map((title, index) => (
                  <Step key={index}>
                    <StepIndicator
                      boxSize={10}
                      _active={{ bg: 'blue.500', color: 'white' }}
                      _complete={{ bg: 'blue.500', color: 'white' }}
                      _incomplete={{ bg: 'gray.200', color: 'gray.500' }}
                    >
                      {index + 1}
                    </StepIndicator>
                    <Box flexShrink="0">
                      <StepStatus
                        complete={<StepTitle>{title}</StepTitle>}
                        incomplete={<StepTitle>{title}</StepTitle>}
                        active={<StepTitle>{title}</StepTitle>}
                      />
                    </Box>
                    {index !== stepTitles.length - 1 && <StepSeparator />}
                  </Step>
                ))}
              </Stepper>
            </Flex>
            <Divider />
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody textAlign="center" fontSize="sm" pb={6}>
            {step === 1 && (
              <Box>
                {maxSpots < 1 && (
                  <Text color="red.500" mb={4}>
                    Your account has reached the limit of allowed spots.                  
                  </Text>
                )}
                <Image
                  src="/images/confirmation.png"
                  alt="Baseball Player Icon"
                  mt="10px"
                  boxSize="60px"
                  mb={4}
                  mx="auto"
                />
                <Text fontSize="lg" mb={4}>How many players do you want to add to your Roster?</Text>
                <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <IconButton
                      aria-label="Increase quantity"
                      icon={<FaCaretUp fontSize="24px" />}
                      onMouseDown={() => handleMouseDown(1)}
                      onMouseUp={handleMouseUp}
                      onMouseLeave={handleMouseUp}
                      size="xs"
                      variant="ghost"
                      _hover={{color: "blue.500"}}
                      isDisabled={quantity >= maxSpots || maxSpots < 1}
                      height="30px"
                      width="30px"
                      mt="-10px"
                    />
                    <IconButton
                      aria-label="Decrease quantity"
                      icon={<FaCaretDown fontSize="24px" />}
                      onMouseDown={() => handleMouseDown(-1)}
                      onMouseUp={handleMouseUp}
                      onMouseLeave={handleMouseUp}
                      size="xs"
                      variant="ghost"
                      _hover={{color: "blue.500"}}
                      isDisabled={quantity <= minQuantity || maxSpots < 1}
                      height="30px"
                      width="30px"
                      mt="-10px"
                    />
                  </Box>
                  <Box width="150px" textAlign="center">
                    <Text fontSize="4xl" fontWeight="bold" mt="-10px">
                      {quantity} / {maxSpots}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}

            {step === 2 && (
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={4}>Select Payment Method</Text>
                <WizardButton
                  variant="next"
                  _hover={{ bg: '#1B154B', opacity: 0.8 }}
                  leftIcon={<FaApple />}
                  mb={3}
                  width="90%"
                  height="50px"
                >
                  Apple Pay
                </WizardButton>
                <WizardButton
                  variant="next"
                  _hover={{ bg: '#1B154B', opacity: 0.8 }}
                  leftIcon={<FaGoogle />}
                  mb={3}
                  width="90%"
                  height="50px"
                >
                  Google Pay
                </WizardButton>
              </Box>
            )}

            {step === 3 && (
              <Box p={4}>
                <Text fontSize="xl" mt="-10px" fontWeight="bold" mb={4}>Review</Text>
                <Flex justify="space-between" mb={3}>
                  <Text fontSize="md">Quantity Of Players</Text>
                  <Text fontSize="md">{quantity} * $6.99</Text>
                </Flex>
                <Flex justify="space-between" mb={3}>
                  <Text fontSize="md">Premium</Text>
                  <Text fontSize="md">$19.99</Text>
                </Flex>
                <Flex justify="space-between" mb={3}>
                  <Text fontSize="md">Taxes</Text>
                  <Text fontSize="md">$1.99</Text>
                </Flex>
                <Flex justify="space-between" fontWeight="bold" mt={4}>
                  <Text fontSize="lg">Total</Text>
                  <Text fontSize="lg">${(quantity * 6.99 + 19.99 + 1.99).toFixed(2)}</Text>
                </Flex>
              </Box>
            )}
          </ModalBody>

          <ModalFooter justifyContent="center" gap={4}>
            {step === 1 && (
              <>
                <WizardButton
                  _hover={{ backgroundColor: '#E2E8F0' }}
                  mt="-60px"
                  width="150px"
                  variant="cancel"
                  onClick={onClose}
                >
                  Cancel
                </WizardButton>
                <WizardButton
                  _hover={{ bg: '#1B154B', opacity: 0.8 }}
                  mt="-60px"
                  width="150px"
                  variant="next"
                  onClick={handleNext}
                  isDisabled={maxSpots < 1}
                >
                  Next
                </WizardButton>
              </>
            )}
            {step === 2 && (
              <>
                <WizardButton
                  _hover={{ backgroundColor: '#E2E8F0' }}
                  mt="-60px"
                  width="150px"
                  variant="back"
                  onClick={handlePrevious}
                >
                  Back
                </WizardButton>
                <WizardButton
                  _hover={{ bg: '#1B154B', opacity: 0.8 }}
                  mt="-60px"
                  width="150px"
                  variant="next"
                  onClick={handleNext}
                >
                  Next
                </WizardButton>
              </>
            )}
            {step === 3 && (
              <>
                <WizardButton
                  _hover={{ backgroundColor: '#E2E8F0' }}
                  mt="-60px"
                  width="150px"
                  variant="back"
                  onClick={handlePrevious}
                >
                  Back
                </WizardButton>
                <WizardButton
                  _hover={{ bg: '#1B154B', opacity: 0.8 }}
                  mt="-60px"
                  width="150px"
                  variant="submit"
                  onClick={handleBuy}
                >
                  Buy
                </WizardButton>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal de confirmación de compra */}
      <Modal isOpen={isConfirmOpen} onClose={closeConfirmModal} isCentered>
        <ModalOverlay />
        <ModalContent width="550px" maxW="90%" p={6}>
          <ModalCloseButton />
          <ModalBody textAlign="center" mt={4}>
            <Icon as={FaCheckCircle} color="green.500" boxSize="50px" mb={4} />
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Payment Successful
            </Text>
            <Text fontSize="md" mb={4}>
              The payment has been successfully completed.
            </Text>
            <Text fontSize="md" fontWeight="medium">
              You have purchased {quantity} spot{quantity > 1 ? 's' : ''} for a total of $
              {(quantity * 6.99 + 19.99 + 1.99).toFixed(2)}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <WizardButton variant="next" onClick={closeConfirmModal}>
              Ok
            </WizardButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BuySpotsModal;
