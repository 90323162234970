import React from "react";
import { Box, Button, Image, Text, Stack, Flex, Icon } from "@chakra-ui/react";
import { IoLocationSharp } from "react-icons/io5";
import { LuExternalLink } from "react-icons/lu";

interface BallParkInterface {
  ballpark: {
    name: string;
    location: string;
    images: string[];
    type: string;
  };
}

const BallParkCard: React.FC<BallParkInterface> = ({ ballpark }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      cursor="pointer"
      border="1px solid"
      borderColor={"custom.gray2"}
      position="relative"
    >
      <Image
        src={ballpark.images[0]}
        alt="Living room Sofa"
        objectFit="cover"
        width="100%"
        height="130px"
      />
      <Stack spacing={2} p={3}>
        <Text
          fontSize="md"
          fontWeight="semibold"
          title={ballpark.name}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {ballpark.name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold" color="custom.black2">
          {ballpark.type}
        </Text>
        <Flex
          fontSize="sm"
          color="custom.black2"
          alignItems="center"
          fontWeight={500}
          gap={2}
        >
          <IoLocationSharp />
          <Box>
            <Text
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              fontSize={"sm"}
              title={ballpark.location}
            >
              {ballpark.location}
            </Text>
          </Box>
        </Flex>
      </Stack>
      <Box
        borderRadius="lg"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)"
        opacity={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="opacity 0.3s ease"
        _hover={{ opacity: 1 }}
      >
        <Icon as={LuExternalLink} color="white" boxSize={6} />
      </Box>
    </Box>
  );
};

export default BallParkCard;
