import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { FaEllipsisH } from 'react-icons/fa';

interface ModernCardProps {
  playerName: string;
  playerPositions: string;
  playerID: string;
}

const ModernCard: React.FC<ModernCardProps> = ({ playerName, playerPositions, playerID }) => (

  <Box
    width="338px"
    height="472px"
    border="4px solid #00aaff"
    borderRadius="10px"
    position="relative"
    backgroundImage={`url('/images/sample${Math.floor(Math.random() * 5) + 1}.JPEG')`}
    backgroundSize="cover"
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    overflow="hidden"
    clipPath="polygon(0 0, 100% 0, 100% 85.2%, 79% 100%, 0 100%)"
  >
    {/* Barra Superior con Logo */}
    <Box
      position="absolute"
      top="0px"
      left="0"
      width="35%"
      height="63px"
      backgroundColor="white"
      clipPath="polygon(0 100%, 60% 100%, 100% 0%, 0 0)"
      display="flex"
      alignItems="center"
      pl={2}
    >
      <Image
        src="/images/StatsLogo.png"
        alt="Logo"
        position="absolute"
        border="4px solid white"
        top="8px"
        left="12px"
        boxSize="48px"
        zIndex={1}
      />
    </Box>

    {/* Barra Inferior con ID del Jugador */}
    <Box
      position="absolute"
      bottom="48px"
      left="0"
      width="30%"
      height="50px"
      backgroundColor="#00aaff"
      clipPath="polygon(0 100%, 100% 100%, 60% 0, 0 0)"
      display="flex"
      alignItems="center"
      pl={2}
    >
      <Text color="white" fontWeight="bold" fontSize="16px">
        {playerID}
      </Text>
    </Box>

    {/* Barra Inferior con Nombre y Posiciones del Jugador */}
    <Box
      position="absolute"
      bottom="-1px"
      left="0"
      width="60%"
      height="50px"
      backgroundColor="#09264a"
      clipPath="polygon(0 100%, 100% 100%, 80% 0, 0 0)"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      pl={2}
    >
      <Text color="white" fontWeight="bold" fontSize="14px">
        {playerName}
      </Text>
      <Text color="white" fontWeight="bold" fontSize="12px">
        {playerPositions
          .split(',')
          .map((item) => item.split(' - ')[0].trim())
          .filter((abbr) => abbr !== '')
          .join(', ')}
      </Text>
    </Box>

    {/* Borde Decorativo en la Esquina Inferior Derecha */}
    <Box
      content=""
      position="absolute"
      bottom="-2px"
      right="0"
      width="20%"
      height="14.5%"
      backgroundColor="white"
      borderRadius="55px 0 0 0"
      border="4px solid #00aaff"
      borderBottom="none"
      borderRight="none"
    />
  </Box>
);

export default React.memo(ModernCard);
