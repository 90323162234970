import React, { useEffect } from "react";
import ellipsis from "../../assets/test/ellipsis.svg";
import plus from "../../assets/test/plus.svg";
import search1 from "../../assets/test/search-1.svg";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchUsers } from "../../store/authSlice";
import {
  Box,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { parseAddressString, removeQuotes } from "../../utils/helpers";

interface UserListProps {
  newUser: boolean;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}
export const UserList = ({
  setNewUser,
  newUser,
}: UserListProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { users, loading, error } = useSelector(
    (state: RootState) => state.auth
  );
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);
  console.log("Users: ", users);
  return (
    <div className="user-list">
      <div className="container">
        <div className="content">
          <h2 className="section-title">User List</h2>
          <div className="actions">
            <div className="search-bar">
              <img className="search-icon" alt="Search" src={search1} />
              <input type="text" placeholder="Search" />
            </div>
            <div className="filters">
              <button className="filter-button active">All</button>
              <button className="filter-button">Manager</button>
              <button className="filter-button">Customer</button>
            </div>
            <button
              className="add-user-button"
              onClick={() => {
                setNewUser(!newUser);
              }}
            >
              <img className="add-icon" alt="Add" src={plus} />
              Add User
            </button>
            <img className="ellipsis-icon" alt="More" src={ellipsis} />
          </div>

          {/* Table Section */}
          <Box
            overflowX="auto"
            width="100%"
            bg="white"
            borderRadius="md"
            shadow="md"
          >
            <TableContainer minWidth="80%">
              <Table variant="simple" minWidth="80%">
                <Thead bg="gray.100">
                  <Tr>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Role</Th>
                    <Th>Tier</Th>
                    <Th>Address</Th>
                    <Th>City</Th>
                    <Th>State</Th>
                    <Th>Country</Th>
                    <Th>Zip Code</Th>
                    <Th>Active</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <Tr>
                      <Td colSpan={11} textAlign="center">
                        Loading...
                      </Td>
                    </Tr>
                  ) : error ? (
                    <Tr>
                      <Td colSpan={11} textAlign="center" color="red.500">
                        {error}
                      </Td>
                    </Tr>
                  ) : (
                    users?.map((user) => {
                      const parsedAddress = parseAddressString(user.ADDRESS);

                      return (
                        <Tr key={user.ID}>
                          <Td>{user.ID}</Td>
                          <Td>
                            {user.FIRST_NAME} {user.LAST_NAME}
                          </Td>
                          <Td>{user.EMAIL}</Td>
                          <Td>{user.ROLE}</Td>
                          <Td>Subscription</Td>
                          <Td
                            maxWidth="200px"
                            whiteSpace="normal"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {parsedAddress.street}
                          </Td>
                          <Td>{parsedAddress.city}</Td>
                          <Td>{parsedAddress.state}</Td>
                          <Td>{"USA"}</Td>
                          <Td>{parsedAddress.zip}</Td>

                          <Td>Active</Td>
                        </Tr>
                      );
                    })
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    </div>
  );
};
