import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

interface WaifVintageCardProps {
  playerName: string;
  playerPositions: string;
  playerID: string;
  uniformNumber: string;
}

// Función para generar las coordenadas de una estrella de 10 puntas
const generateStarPoints = (
  centerX: number,
  centerY: number,
  radiusOuter: number,
  radiusInner: number,
  numPoints: number
) => {
  const points = [];
  const step = Math.PI / numPoints; // Ángulo entre cada punto

  for (let i = 0; i < 2 * numPoints; i++) {
    const radius = i % 2 === 0 ? radiusOuter : radiusInner; // Alternar entre radio externo e interno
    const x = centerX + radius * Math.cos(i * step);
    const y = centerY - radius * Math.sin(i * step); // Restar para que las coordenadas vayan hacia arriba
    points.push(`${x},${y}`);
  }

  return points.join(' ');
};

const WaifVintageCard: React.FC<WaifVintageCardProps> = ({
  playerName,
  playerPositions,
  playerID,
  uniformNumber,
}) => {
  // Generar coordenadas para una estrella de 10 puntas con puntas pequeñas
  const starPoints = generateStarPoints(50, 50, 40, 30, 10);

  return (
    <Box
      width="338px"
      height="460px"
      borderWidth="4px" 
      borderColor="#363838" 
      borderRadius="10px"
      zIndex={11} 
      borderStyle="solid" 
      >
    <Box
      width="330px"
      height="448px"
      borderWidth="10px" 
      borderStyle="solid"
      borderRadius="10px" 
      position="relative"
      backgroundImage={`url('/images/sample${Math.floor(Math.random() * 5) + 1}.JPEG')`} 
      filter="grayscale(100%)"
      backgroundSize="cover" 
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      overflow="hidden"
      sx={{
        borderImageSource: "url('/images/texture2.png')",
        borderImageSlice: 70,
        borderImageRepeat: "stretch", 
      }}
      >
      <Box
        position="absolute"
        borderRadius="10px"
        top="8px"
        left="8px"
        zIndex={3}
        width="70px"
        height="70px"
        >
        <svg width="70px" height="70px" viewBox="0 0 100 100">
          <defs>
            {/* Definición del clipPath con la forma de la estrella */}
            <clipPath id="starClip">
              <polygon points={starPoints} />
            </clipPath>
          </defs>
          {/* Imagen de la textura recortada en la forma de la estrella */}
          <image
            href="/images/texture2.png" 
            x="0"
            y="0"
            width="100"
            height="100"
            clipPath="url(#starClip)"
            preserveAspectRatio="xMidYMid slice" 
            />
          {/* Contorno de la estrella */}
          <polygon
            points={starPoints}
            fill="none"
            stroke="#363838"
            strokeWidth="4"
            strokeLinejoin="round" 
            />
          {/* Número de uniforme centrado en la estrella */}
          <text
            x="50"
            y="52"
            textAnchor="middle"
            fontSize="25"
            fill="#363838"
            fontWeight="bold"
            dominantBaseline="middle"
            >
            {uniformNumber}
          </text>
        </svg>
      </Box>
      <Box
        position="absolute"
        bottom="-1px"
        width="100%"
        height="70px"
        background="url('/images/texture2.png')"
        clipPath="polygon(0 100%, 100% 100%, 100% 70%, 0 0)"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        pt={6}
        >
        <Text color="#363838" fontWeight="bold" fontSize="14px" mr="2px">
          ({playerID}){" "}
          {playerPositions
            .split(",")
            .map((item) => item.split(" - ")[0].trim())
            .filter((abbr) => abbr !== "")
            .join(", ")}
        </Text>
        <Text color="#363838" fontWeight="bold" fontSize="20px">
          {playerName}
        </Text>
      </Box>
    </Box>
    </Box>
  );
};

export default React.memo(WaifVintageCard);
