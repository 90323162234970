import { AddIcon, SearchIcon, SmallAddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import BallParkSlider from "../components/ballparks/BallParkSlider";
import ViewBallPark from "../components/ballparks/ViewBallPark";

const BallParks = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeBallpark, setActiveBallpark] = useState(1);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const ballparks = [
    {
      id: 1,
      name: "Hiram Bithorn Stadium",
      location: "San Juan, Puerto Rico",
      images: [
        "./images/ballparks/Hiram_Bithorn_Stadium.jpg",
        "./images/ballparks/ballpark-2.png",
        "./images/ballparks/ballpark-3.png",
      ],
      fullAddress:
        "Avenida Franklin Delano Roosevelt, San Juan, Puerto Rico 00918",
      capacity: "18,264",
      group: "Media Group",
      mobileNumber: "+1 (787) 555-1234",
      googleMapsLink:
        "https://www.google.com/maps?q=Hiram+Bithorn+Stadium,+San+Juan,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1962", // Date built
      dimensions: "325 ft (left), 404 ft (center), 325 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 2,
      name: "Loíza Valley",
      location: "Loíza, Puerto Rico",
      images: ["./images/ballparks/Hiram_Bithorn_Stadium.jpg"],
      fullAddress: "Loíza, Puerto Rico",
      group: "Media Group",
      capacity: "5,000",
      mobileNumber: "+1 (787) 555-5678",
      googleMapsLink: "https://www.google.com/maps?q=Loíza+Valley,+Puerto+Rico",
      type: "Community Park",
      dateBuilt: "1998", // Approximate date
      dimensions: "310 ft (left), 390 ft (center), 310 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 3,
      name: "Francisco Montaner Stadium",
      location: "Ponce, Puerto Rico",
      images: ["./images/ballparks/20180420_112535.jpg"],
      fullAddress: "Calle Concordia, Ponce, Puerto Rico 00730",
      group: "Media Group",
      capacity: "16,000",
      mobileNumber: "+1 (787) 555-8765",
      googleMapsLink:
        "https://www.google.com/maps?q=Francisco+Montaner+Stadium,+Ponce,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1949", // Date built
      dimensions: "335 ft (left), 400 ft (center), 335 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 4,
      name: "Estadio Néstor Morales",
      location: "Humacao, Puerto Rico",
      images: ["./images/ballparks/Hiram_Bithorn_Stadium.jpg"],
      fullAddress: "Humacao, Puerto Rico",
      group: "Media Group",
      capacity: "8,000",
      mobileNumber: "+1 (787) 555-4321",
      googleMapsLink:
        "https://www.google.com/maps?q=Estadio+Néstor+Morales,+Humacao,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1990", // Date built
      dimensions: "330 ft (left), 400 ft (center), 330 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 5,
      name: "Roberto Clemente Stadium",
      location: "Carolina, Puerto Rico",
      images: ["./images/ballparks/250px-Estadio_Roberto_Clemente_Walker.jpg"],
      fullAddress: "65 Infantería Avenue Carolina, Puerto Rico",
      group: "Media Group",
      capacity: "12,500",
      mobileNumber: "+1 (787) 555-1010",
      googleMapsLink:
        "https://www.google.com/maps?q=Roberto+Clemente+Stadium,+Carolina,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "2000", // Date built
      dimensions: "325 ft (left), 405 ft (center), 325 ft (right)", // Dimensions
      surfaceType: "Artificial turf", // Surface type
    },
    {
      id: 6,
      name: "Isidoro García Stadium",
      location: "Mayagüez, Puerto Rico",
      images: ["./images/ballparks/Seats_in_cholo.jfif"],
      fullAddress: "Avenida Colby William Duscombe, Mayagüez, Puerto Rico",
      group: "Media Group",
      capacity: "10,500",
      mobileNumber: "+1 (787) 555-2020",
      googleMapsLink:
        "https://www.google.com/maps?q=Isidoro+García+Stadium,+Mayagüez,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1981", // Date built
      dimensions: "320 ft (left), 400 ft (center), 320 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 7,
      name: "Francisco Montaner Stadium",
      location: "Ponce, Puerto Rico",
      images: ["./images/ballparks/20180420_112535.jpg"],
      fullAddress: "Calle Concordia, Ponce, Puerto Rico 00730",
      group: "Media Group",
      capacity: "16,000",
      mobileNumber: "+1 (787) 555-8765",
      googleMapsLink:
        "https://www.google.com/maps?q=Francisco+Montaner+Stadium,+Ponce,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1949", // Date built
      dimensions: "335 ft (left), 400 ft (center), 335 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 8,
      name: "Estadio Néstor Morales",
      location: "Humacao, Puerto Rico",
      images: ["./images/ballparks/Hiram_Bithorn_Stadium.jpg"],
      fullAddress: "Humacao, Puerto Rico",
      group: "Media Group",
      capacity: "8,000",
      mobileNumber: "+1 (787) 555-4321",
      googleMapsLink:
        "https://www.google.com/maps?q=Estadio+Néstor+Morales,+Humacao,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1990", // Date built
      dimensions: "330 ft (left), 400 ft (center), 330 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
    {
      id: 9,
      name: "Roberto Clemente Stadium",
      location: "Carolina, Puerto Rico",
      images: ["./images/ballparks/250px-Estadio_Roberto_Clemente_Walker.jpg"],
      fullAddress: "65 Infantería Avenue Carolina, Puerto Rico",
      group: "Media Group",
      capacity: "12,500",
      mobileNumber: "+1 (787) 555-1010",
      googleMapsLink:
        "https://www.google.com/maps?q=Roberto+Clemente+Stadium,+Carolina,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "2000", // Date built
      dimensions: "325 ft (left), 405 ft (center), 325 ft (right)", // Dimensions
      surfaceType: "Artificial turf", // Surface type
    },
    {
      id: 10,
      name: "Isidoro García Stadium",
      location: "Mayagüez, Puerto Rico",
      images: ["./images/ballparks/Seats_in_cholo.jfif"],
      fullAddress: "Avenida Colby William Duscombe, Mayagüez, Puerto Rico",
      group: "Media Group",
      capacity: "10,500",
      mobileNumber: "+1 (787) 555-2020",
      googleMapsLink:
        "https://www.google.com/maps?q=Isidoro+García+Stadium,+Mayagüez,+Puerto+Rico",
      type: "Stadium",
      dateBuilt: "1981", // Date built
      dimensions: "320 ft (left), 400 ft (center), 320 ft (right)", // Dimensions
      surfaceType: "Natural grass", // Surface type
    },
  ];

  const filteredBallparks = ballparks.filter(
    (ballpark) =>
      ballpark.name.toLowerCase().includes(searchTerm) ||
      ballpark.location.toLowerCase().includes(searchTerm) ||
      ballpark.type.toLowerCase().includes(searchTerm)
  );

  const handleActive = (id: number) => {
    setActiveBallpark(id);
  };

  return (
    <>
      <ViewBallPark ballparks={ballparks} active={activeBallpark} />
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={1}
        justify="space-between"
        align={{ base: "stretch", md: "center" }}
        my={6}
      >
        <InputGroup
          maxW={{ base: "100%", md: "300px" }}
          minW={{ base: "100%", sm: "150px" }}
          mb={{ base: 4, md: 0 }}
        >
          <InputLeftElement pointerEvents="none">
            <SearchIcon />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search..."
            color={"#545560"}
            bg={"white"}
            border="1px solid gray"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </InputGroup>

        <Flex
          align="center"
          gap={4}
          ml={{ base: 0, md: "auto" }}
          justify={{ base: "space-between", md: "flex-end" }}
          width={{ base: "100%", md: "auto" }}
        >
          <Button
            variant="ghost"
            bg={"#fff"}
            _hover={{ bg: "#f6f6f6" }}
            border={"1px solid"}
            borderColor={"custom.gray1"}
            p={0}
            px={4}
            width={{ base: "100%", sm: "auto" }}
          >
            <Flex alignItems={"center"} justifyContent={"center"} gap={2}>
              <HiMiniArrowsUpDown size={20} />
              Sort by
            </Flex>
          </Button>
          <Button
            bg={"custom.blue"}
            _hover={{ opacity: "0.9" }}
            _active={{ opacity: "0.9" }}
            border={"1px solid"}
            borderColor={"custom.blue"}
            p={0}
            px={4}
            width={{ base: "100%", sm: "auto" }}
            color={"white"}
          >
            <Flex alignItems={"center"} justifyContent={"center"} gap={2}>
              <AddIcon />
              Add Ballpark
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Text mt={3} fontSize="2xl" fontWeight="semibold">
        Game Parks
      </Text>
      <Box position={"relative"} height="auto" width="100%">
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="auto"
          pb={4}
        >
          <BallParkSlider
            ballparks={filteredBallparks}
            handleActive={handleActive}
          />
        </Box>
      </Box>
    </>
  );
};

export default BallParks;
