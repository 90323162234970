import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  List,
  ListItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Player } from '../../types/pages/player';
import ConfirmationModal from './ConfirmationModal';
import RegisterSuccessModal from './RegisterSuccessModal';
import RegisterErrorModal from './RegisterErrorModal';
import { playingPositionsOptions } from '../../constants/options';

interface PlayerRegistrationPopupProps {
  openRegistrationModal: boolean;
  toggleRegisterPopup: () => void;
  onAddPlayer: (newPlayer: Player) => void;
  playerToEdit?: Player | null; // Nuevo prop
  onUpdatePlayer?: (updatedPlayer: Player) => void; // Nuevo prop
}

const PlayerRegistrationModal: React.FC<PlayerRegistrationPopupProps> = ({
  toggleRegisterPopup,
  openRegistrationModal,
  onAddPlayer,
  playerToEdit,
  onUpdatePlayer,
}) => {
  const { isOpen: isConfirmationOpen, onOpen: openConfirmation, onClose: closeConfirmation } = useDisclosure();
  const { isOpen: isSuccessOpen, onOpen: openSuccess, onClose: closeSuccess } = useDisclosure();
  const { isOpen: isErrorOpen, onOpen: openError, onClose: closeError } = useDisclosure();

  const [submittedPlayer, setSubmittedPlayer] = useState<any>(null);
  const [playingPositionInput, setPlayingPositionInput] = useState<string>('');
  const [playingPositionSuggestions, setPlayingPositionSuggestions] = useState<string[]>([]);
  const playingPositionInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const isEditMode = !!playerToEdit; // Determinar el modo

  const initialValues = {
    firstname: playerToEdit?.FIRST_NAME || '',
    lastname: playerToEdit?.LAST_NAME || '',
    maidenName: playerToEdit?.MAIDEN_NAME || '',
    dateOfBirth: playerToEdit?.DATE_OF_BIRTH ? new Date(playerToEdit.DATE_OF_BIRTH).toISOString().split('T')[0] : '',
    playingPositions: playerToEdit?.PLAYING_POSITIONS ? playerToEdit.PLAYING_POSITIONS.split(', ') : [] as string[],
    uniformNumber: playerToEdit?.UNIFORM_NUMBER || '',
    guardianEmail: playerToEdit?.GUARDIAN_EMAIL || '',
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]+$/, 'Only letters are allowed')
      .required('First name is required'),
    lastname: Yup.string()
      .matches(/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]+$/, 'Only letters are allowed')
      .required('Last name is required'),
    maidenName: Yup.string().matches(/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]*$/, 'Only letters are allowed'),
    dateOfBirth: Yup.date()
      .required('Date of birth is required')
      .test(
        'age-range',
        'Age must be between 3 and 25 years',
        function (value) {
          const age = value ? new Date().getFullYear() - new Date(value).getFullYear() : null;
          return age !== null && age >= 3 && age <= 25;
        }
      ),
    playingPositions: Yup.array()
      .of(Yup.string().required('Invalid Playing Position'))
      .min(1, 'Please select at least one Playing Position')
      .max(3, 'You can select up to 3 Playing Positions')
      .required('Playing Positions are required'),
    uniformNumber: Yup.string()
      .matches(/^\d{1,3}$/, 'Uniform Number must be 1 to 3 digits')
      .required('Uniform number is required'),
    guardianEmail: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
      .required('Guardian email is required!'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmittedPlayer(values); // Establecer los datos del jugador
      openConfirmation(); // Abrir modal de confirmación
    },
    validateOnMount: false,
    enableReinitialize: true,
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
  } = formik;

  useEffect(() => {
    if (openRegistrationModal) {
      resetForm();
    }
  }, [openRegistrationModal, resetForm]);

  const handlePlayingPositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPlayingPositionInput(inputValue);

    if (inputValue.length > 0) {
      const filteredPositions = playingPositionsOptions.filter((position: string) =>
        position.toLowerCase().includes(inputValue.toLowerCase())
      );
      setPlayingPositionSuggestions(filteredPositions);
    } else {
      setPlayingPositionSuggestions([]);
    }
  };

  const addPlayingPositionHandler = async (position: string) => {
    if (
      position.trim() !== '' &&
      !formik.values.playingPositions.includes(position) &&
      formik.values.playingPositions.length < 3 &&
      playingPositionsOptions.includes(position)
    ) {
      await formik.setFieldValue('playingPositions', [
        ...formik.values.playingPositions,
        position,
      ]);
      await formik.setFieldTouched('playingPositions', true);
      setPlayingPositionInput('');
      setPlayingPositionSuggestions([]);
      if (playingPositionInputRef.current) {
        playingPositionInputRef.current.focus();
      }
    } else if (formik.values.playingPositions.length >= 3) {
      toast({
        title: 'Limit Reached',
        description: 'You can select up to 3 playing positions.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    } else if (!playingPositionsOptions.includes(position)) {
      toast({
        title: 'Invalid Position',
        description: 'Please select a valid playing position.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const removePlayingPosition = async (position: string) => {
    const updatedPositions = formik.values.playingPositions.filter(
      (p: string) => p !== position
    );
    await formik.setFieldValue('playingPositions', updatedPositions);
    await formik.setFieldTouched('playingPositions', true);
  };

  return (
    <>
      <Modal
        onClose={() => { toggleRegisterPopup(); resetForm(); }}
        isOpen={openRegistrationModal}
        size={{ base: 'xs', sm: 'sm', md: '2xl', lg: '3xl' }}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(3px)" />
        <ModalContent my="auto" pt="18px" pb="10px" borderRadius="xl">
          <Flex justifyContent="space-between" px="30px" pb="15px">
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Text color="custom.black" fontWeight="bold" fontSize={18}>
                {isEditMode ? 'Edit Player' : 'Registration'}
              </Text>
            </Stack>
          </Flex>
          <hr />
          <ModalBody
            display="flex"
            flexDirection="column"
            fontSize="18px"
            gap={{ base: 3 }}
            mt={{ base: '10px' }}
            px="30px"
          >
            <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
              {/* First Name */}
              <FormControl id="firstname" isRequired isInvalid={!!errors.firstname && touched.firstname}>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  placeholder="First name"
                  name="firstname"
                  value={values.firstname}
                  onChange={(e) => {
                    if (/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]*$/.test(e.target.value)) handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.firstname}</FormErrorMessage>
              </FormControl>
              
              {/* Last Name */}
              <FormControl id="lastname" isRequired isInvalid={!!errors.lastname && touched.lastname}>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Last name"
                  name="lastname"
                  value={values.lastname}
                  onChange={(e) => {
                    if (/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]*$/.test(e.target.value)) handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.lastname}</FormErrorMessage>
              </FormControl>

              {/* Maiden Name */}
              <FormControl id="maidenName" isInvalid={!!errors.maidenName && touched.maidenName}>
                <FormLabel>Maiden Name (Optional)</FormLabel>
                <Input
                  type="text"
                  placeholder="Maiden name"
                  name="maidenName"
                  value={values.maidenName}
                  onChange={(e) => {
                    if (/^[A-Za-záéíóúÁÉÍÓÚñÑ\s]*$/.test(e.target.value)) handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.maidenName}</FormErrorMessage>
              </FormControl>

              {/* Date of Birth */}
              <FormControl id="dateOfBirth" isRequired isInvalid={!!errors.dateOfBirth && touched.dateOfBirth}>
                <FormLabel>Date of Birth</FormLabel>
                <Input
                  type="date"
                  placeholder="Select date"
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.dateOfBirth}</FormErrorMessage>
              </FormControl>

              {/* Playing Positions */}
              <FormControl id="playingPositions" isRequired isInvalid={!!errors.playingPositions && touched.playingPositions}>
                <FormLabel>Playing Positions</FormLabel>
                <Box position="relative">
                  <Input
                    name="playingPositionInput"
                    placeholder="Select playing position"
                    value={playingPositionInput}
                    onChange={handlePlayingPositionChange}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addPlayingPositionHandler(playingPositionInput);
                      }
                    }}
                    onBlur={() => { 
                      setPlayingPositionInput(''); 
                      setPlayingPositionSuggestions([]); 
                    }} // Limpiar el input y sugerencias al perder foco
                    ref={playingPositionInputRef}
                    autoComplete="off"
                  />
                  {playingPositionSuggestions.length > 0 && (
                    <Box
                      position="absolute"
                      top="100%"
                      left="0"
                      right="0"
                      bg="white"
                      border="1px solid gray.100"
                      borderRadius="md"
                      maxH="200px"
                      overflowY="auto"
                      zIndex="1"
                    >
                      <List
                        spacing={1}
                        maxHeight="120px" // Limita a 3 elementos (asumiendo 40px por item)
                        overflowY="auto" // Habilita el desplazamiento vertical
                        border="2px solid" // Define el ancho y estilo del borde
                        borderColor="gray.200" // Define el color del borde (puedes ajustar el tono)
                        borderRadius="md" // Opcional: bordes redondeados
                        boxShadow="sm" // Opcional: sombra sutil para mejorar la apariencia
                        backgroundColor="white" // Opcional: fondo blanco para mayor contraste
                      >
                        {playingPositionSuggestions.map((position: string, index: number) => (
                          <ListItem
                            key={index}
                            px={4}
                            py={2}
                            fontSize="16px"
                            _hover={{ bg: '#2C5282', color: 'white', cursor: 'pointer' }}
                            onMouseDown={() => addPlayingPositionHandler(position)} // onMouseDown para que se ejecute antes del onBlur
                          >
                            {position}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>
                <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                  {formik.values.playingPositions.map((position: string, index: number) => (
                    <Tag
                      size="md"
                      key={index}
                      borderRadius="full"
                      variant="solid"
                      bg="#09264a"
                      color="white"
                    >
                      <TagLabel>{position}</TagLabel>
                      <TagCloseButton onClick={() => removePlayingPosition(position)} />
                    </Tag>
                  ))}
                </Box>
                <FormErrorMessage>{errors.playingPositions as string}</FormErrorMessage>
              </FormControl>

              {/* Uniform Number */}
              <FormControl id="uniformNumber" isRequired isInvalid={!!errors.uniformNumber && touched.uniformNumber}>
                <FormLabel>Uniform Number</FormLabel>
                <Input
                  type="text"
                  placeholder="Uniform number"
                  name="uniformNumber"
                  value={values.uniformNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,3}$/.test(value)) {
                      formik.setFieldValue("uniformNumber", value);
                    }
                  }}
                  onBlur={handleBlur}
                  maxLength={3}
                />
                <FormErrorMessage>{errors.uniformNumber}</FormErrorMessage>
              </FormControl>

              {/* Guardian Email */}
              <FormControl id="guardianEmail" isRequired isInvalid={!!errors.guardianEmail && touched.guardianEmail}>
                <FormLabel>Guardian Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  name="guardianEmail"
                  value={values.guardianEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.guardianEmail}</FormErrorMessage>
              </FormControl>
            </Grid>
            </ModalBody>
          <ModalFooter display="flex" justifyContent="end" gap="20px" px="30px">
            <Button
              variant="outline"
              color="#545560"
              borderColor="#545560"
              h={12}
              w="150px"
              onClick={() => { toggleRegisterPopup(); resetForm(); }}
            >
              Cancel
            </Button>
            <Button
              bgColor="#1B154B"
              variant="solid"
              colorScheme="darkpurple"
              color="white"
              h={12}
              onClick={submitForm}
              _hover={{ bg: '#1B154B', opacity: 0.8 }}
              w="150px"
            >
              {isEditMode ? 'Update' : 'Save'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        actionType={isEditMode ? 'update' : 'create'}
        closeConfirmation={closeConfirmation}
        isConfirmationOpen={isConfirmationOpen}
        submittedPlayer={submittedPlayer}
        onSuccess={(updatedPlayer) => {
          if (isEditMode && onUpdatePlayer) {
            onUpdatePlayer(updatedPlayer);
          } else {
            onAddPlayer(updatedPlayer);
          }
          openSuccess();
        }}
        playerId={isEditMode ? playerToEdit?.PLAYER_ID : undefined}
      />

      {/* Success Modal */}
      <RegisterSuccessModal
        closeSuccess={() => {
          closeSuccess();
          toggleRegisterPopup();
        }}
        isSuccessOpen={isSuccessOpen}
        actionType={isEditMode ? 'update' : 'create'}
      />

      {/* Error Modal */}
      <RegisterErrorModal closeError={closeError} isErrorOpen={isErrorOpen} />
    </>
  );
};

export default PlayerRegistrationModal;