import React from 'react';
import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import axiosInstance from '../../api/axiosConfig';

interface SubmittedPlayer {
  firstname: string;
  lastname: string;
  maidenName?: string;
  dateOfBirth: string;
  playingPositions?: string[]; 
  uniformNumber?: string;
  guardianEmail: string;
}

interface ConfirmationProps {
  closeConfirmation: () => void;
  isConfirmationOpen: boolean;
  submittedPlayer: SubmittedPlayer;
  actionType: 'create' | 'update';
  onSuccess: (updatedPlayer: any) => void;
  playerId?: string;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  closeConfirmation,
  isConfirmationOpen,
  submittedPlayer,
  actionType,
  onSuccess,
  playerId,
}) => {
  const toast = useToast();

  const confirmSubmission = async () => {
    try {
      const payload = {
        formData: {
          firstName: submittedPlayer.firstname,
          lastName: submittedPlayer.lastname,
          maidenName: submittedPlayer.maidenName || null,
          dateOfBirth: submittedPlayer.dateOfBirth,
          playingPositions: submittedPlayer.playingPositions
            ? submittedPlayer.playingPositions.join(', ')
            : null,
          uniformNumber: submittedPlayer.uniformNumber || null,
          guardianEmail: submittedPlayer.guardianEmail,
        },
      };

      // Pre-check for duplicate players (only for creation)
      if (actionType === 'create') {
        try {
          const duplicateCheck = await axiosInstance.get('/players/getPlayerByDetails', { 
            params: {
              firstName: submittedPlayer.firstname,
              lastName: submittedPlayer.lastname,
              maidenName: submittedPlayer.maidenName || null,
              dateOfBirth: submittedPlayer.dateOfBirth,
            },
          });

          console.log('Duplicate Check Response:', duplicateCheck);

          if (duplicateCheck?.data?.player) {
            const existingPlayer = duplicateCheck.data.player;
            toast({
              title: 'Conflict',
              description: `Player ${existingPlayer.FIRST_NAME} ${existingPlayer.MAIDEN_NAME || ''} ${existingPlayer.LAST_NAME} born ${existingPlayer.DATE_OF_BIRTH.split('T')[0]} already exists.`,
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
            return; // Stop the flow if duplicate is detected
          }
        } catch (error: any) {
          if (error.response?.status !== 404) { // 404 means no duplicate found
            console.error('Error checking duplicates:', error.response || error.message);
            toast({
              title: 'Error',
              description: 'There was an error checking for duplicates. Please try again.',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
            return;
          }
        }
      }

      let response;

      if (actionType === 'update' && playerId) {
        // Send update request
        response = await axiosInstance.put(`/players/${playerId}`, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        // Send create request
        response = await axiosInstance.post('/players/waif', payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      if (response?.data?.success) {
        toast({
          title: `Player ${actionType === 'create' ? 'Created' : 'Updated'}`,
          description: `Player ${submittedPlayer.firstname} ${submittedPlayer.lastname} was successfully ${actionType === 'create' ? 'created' : 'updated'}.`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        onSuccess(response.data.player); // Execute action upon completion
        closeConfirmation();
      } else {
        throw new Error('Error in response data');
      }
    } catch (error: any) {
      if (error.response?.status === 409) {
        // Duplicate error from backend
        toast({
          title: 'Conflict',
          description: error.response.data.message || 'Player already exists.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      } else if (error.response?.status === 400 && error.response.data.message.includes('available spots')) {
        // Availability error
        toast({
          title: 'No Available Spots',
          description: error.response.data.message || 'No available spots for this guardian. Please contact support.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error('Error in confirmSubmission:', error.response || error.message);
        toast({
          title: 'Error',
          description: `There was an error trying to ${actionType} the player. Please try again.`,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal onClose={closeConfirmation} isOpen={isConfirmationOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody paddingTop={6} px={{ base: 4, md: 12 }}>
          <Flex direction="column" alignItems="center" textAlign="center" justifyContent="center" mb={4}>
            <Image
              alt="Illustration of a baseball player with a bat and helmet"
              boxSize="80px"
              src="/images/confirmation.png"
            />
            <Text py={4} fontSize="xl" fontWeight="bold">
              {submittedPlayer?.firstname} {submittedPlayer?.lastname}
            </Text>
            <Text fontWeight="bold">
              Are you sure you want to {actionType === 'create' ? 'create' : 'update'} this player?
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" gap="4" px="30px">
          <Button
            variant="outline"
            color="#545560"
            borderColor="#545560"
            h={12}
            w="150px"
            onClick={closeConfirmation}
          >
            Cancel
          </Button>
          <Button
            bgColor="#1B154B"
            variant="solid"
            colorScheme="darkpurple"
            color="white"
            h={12}
            onClick={confirmSubmission}
            _hover={{ bg: '#1B154B', opacity: 0.8 }}
            w="150px"
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;