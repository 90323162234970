import { Box, Image, Icon } from "@chakra-ui/react";
import { LuExternalLink } from "react-icons/lu";

interface Previewcardinterface {
  src: string;
}

const PreviewImageCard: React.FC<Previewcardinterface> = ({ src }) => {
  return (
    <Box
      position="relative"
      border="1px solid black"
      borderRadius="xl"
      overflow="hidden"
      width="100%"
      height="100%"
    >
      <Image
        src={src}
        alt="Stadium"
        objectFit="cover"
        width="180px"
        height="100%"
        borderRadius="xl"
        transition="all 0.3s ease"
      />
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.5)"
        opacity={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="opacity 0.3s ease"
        _hover={{ opacity: 1 }}
      >
        <Icon as={LuExternalLink} color="white" boxSize={6} />
      </Box>
    </Box>
  );
};

export default PreviewImageCard;
