// src/App.tsx
import React from "react";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./routes";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./utils/theme";
import { PlayerFormProvider } from "./context/PlayerFormContext";
import { EditPlayerFormProvider } from "./context/EditPlayerFormContext";

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <EditPlayerFormProvider>
          <PlayerFormProvider>
            <AppRoutes />
          </PlayerFormProvider>
        </EditPlayerFormProvider>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
