import {
  Flex,
  Text,
  Box,
  Avatar,
  useBreakpointValue,
  Spinner,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchUserInfo } from "../../store/authSlice";
import { mapUserAttributes } from "../../utils/helpers";
import {
  CreditCardIcon,
  EditIcon,
  NotificationBell,
  DownArrow,
} from "../../assets/SVGs/Global";
import CustomPopup from "./CustomPopup";
import EditProfilePopup from "../popups/EditProfilePopup";
import PaymentPopup from "../popups/PaymentPopup";
import { useLocation } from "react-router-dom";

const Topbar = ({ isSidebarCollapsed }: { isSidebarCollapsed: boolean }) => {
  const titleFontSize = useBreakpointValue({ base: "lg", md: "xl" });
  const dispatch = useDispatch<AppDispatch>();
  const [user, setUser] = useState<any>(null);
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const location = useLocation();

  // Obtener el estado loading de Redux
  const { loading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    console.log("Topbar useEffect");
    const fetchAndStoreUser = async () => {
      setUser(null); // Reiniciar el estado del usuario
      const accessToken = localStorage.getItem("accessToken");
      const storedUser = localStorage.getItem("user");

      if (storedUser) {
        setUser(JSON.parse(storedUser));
      } else if (accessToken) {
        try {
          const result = await dispatch(
            fetchUserInfo({ accessToken })
          ).unwrap();
          if (result?.user?.UserAttributes) {
            const userAttributes = result.user;
            const userObject = mapUserAttributes(userAttributes.UserAttributes);
            if (userObject) {
              localStorage.setItem("user", JSON.stringify(userObject));
              setUser(userObject);
            } else {
              console.warn("User object is null or undefined.");
            }
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      } else {
        console.warn("No access token available.");
      }
    };

    fetchAndStoreUser();
  }, [dispatch]);

  const getTitle = () => {
    switch (location.pathname) {
      case "/news":
        return "News";
      case "/league":
        return "League";
      case "/team":
        return "Team";
      case "/players":
        return "Players";
      case "/ballparks":
        return "Ballparks";
      case "/accounts":
        return "Accounts";
      case "/scout-access":
        return "Scout Access";
      case "/settings":
        return "Settings";
      default:
        return "Player Registry";
    }
  };

  useEffect(() => {
    document.title = getTitle();
  }, [location]);
  console.log("Topbar user: ", user);
  return (
    <Flex
      h="80px"
      alignItems="center"
      justifyContent="space-between"
      bg="custom.white"
      borderBottom="1px"
      borderColor="custom.outline"
      zIndex="1"
      pe={{ base: 3, md: 6 }}
      ps={{ base: 3, md: 12 }}
    >
      <Text fontSize={titleFontSize} fontWeight="semibold">
        {getTitle()}
      </Text>

      <Flex alignItems="center" gap={{ base: 2, md: 4, xl: 8 }}>
        <Box display={{ base: "none", sm: "block" }}>
          <Button
            p={0}
            m={0}
            justifyContent="center"
            variant="ghost"
            position="relative"
            _hover={{ bg: "custom.bg" }}
          >
            <NotificationBell />
            <Flex
              position="absolute"
              top={1}
              right={1}
              bg="custom.red"
              color="white"
              h={4}
              w={4}
              zIndex="10"
              borderRadius="full"
              justifyContent="center"
              alignItems="center"
              fontSize="10px"
            >
              5
            </Flex>
          </Button>
        </Box>
        <Divider
          display={{ base: "none", sm: "block" }}
          orientation="vertical"
          w="1px"
          h={5}
          bg="#D4D4E4"
        />

        {loading || !user ? (
          <Spinner size="lg" color="blue.500" />
        ) : (
          <Flex alignItems="center" ml={2} gap={{ base: 1, md: 4 }}>
            <Avatar
              size="sm"
              name={user?.given_name}
              src="path/to/avatar.jpg"
            />
            <Box w={28} display={{ base: "none", md: "block" }}>
              <Text fontWeight="bold">{`${user?.given_name} ${user?.family_name}`}</Text>
              <Text fontWeight="semibold" fontSize="sm" color="custom.gray1">
                {`${user?.["custom:role"] || "Role"}`}
              </Text>
            </Box>
            <Box ml={{ base: 0, md: 4 }}>
              <Menu>
                <MenuButton
                  bg="custom.white"
                  borderRadius="5px"
                  _hover={{ bg: "custom.bg" }}
                >
                  <Button p={0} m={0} justifyContent="center" variant="ghost">
                    <DownArrow />
                  </Button>
                </MenuButton>
                <MenuList
                  paddingInline="5px"
                  border="none"
                  mt={4}
                  minW={{ base: "none", sm: "250px" }}
                >
                  <MenuItem
                    pl={4}
                    py={3}
                    mt={1}
                    fontWeight={600}
                    icon={<EditIcon />}
                    value="editProfile"
                    borderRadius="5px"
                    _hover={{ bg: "custom.bg" }}
                    _focus={{ bg: "custom.bg" }}
                    onClick={() => setIsProfilePopupOpen(true)}
                  >
                    Edit Profile
                  </MenuItem>
                  <MenuItem
                    hidden={user && user?.["custom:role"] === "Manager"}
                    pl={4}
                    py={3}
                    mt={2}
                    mb={1}
                    fontWeight={600}
                    borderRadius="5px"
                    value="updatePayment"
                    icon={<CreditCardIcon />}
                    _hover={{ bg: "custom.bg" }}
                    _focus={{ bg: "custom.bg" }}
                    onClick={() => setIsPaymentPopupOpen(true)}
                  >
                    Update Payment
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        )}
      </Flex>

      {/* Edit Profile Popup */}
      <EditProfilePopup
        user={user}
        isProfilePopupOpen={isProfilePopupOpen}
        setIsProfilePopupOpen={setIsProfilePopupOpen}
      />

      {/* Edit Payment Popup */}
      <PaymentPopup
        isPaymentPopupOpen={isPaymentPopupOpen}
        setIsPaymentPopupOpen={setIsPaymentPopupOpen}
      />
    </Flex>
  );
};

export default Topbar;
