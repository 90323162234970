import React, { createContext, useState, useEffect } from 'react';

interface EditFormValues {
  firstName: string;
  initial: string;
  maidenName: string;
  lastName: string;
  dateOfBirth: string;
  playingPositions: string[]; // Siempre un array de strings
  uniformNumber: string;
  city: string;
  state: string;
  country: string;
  graduationYear: string;
  graduationYearOption: string;
  currentSchool: string;
  aimingColleges: string[]; // Siempre un array de strings
  last4SN: string;
  weight: string;
  height: string;
  throwingHand: string;
  battingHand: string;
  legalWaiver: boolean;
  selectedPlan: string;
}

interface InitialEditFormValues extends Omit<EditFormValues, 'playingPositions' | 'aimingColleges'> {
  playingPositions?: string | string[];
  aimingColleges?: string | string[];
}

interface EditPlayerFormContextProps {
  formData: EditFormValues;
  setFormData: React.Dispatch<React.SetStateAction<EditFormValues>>;
}

interface EditPlayerFormProviderProps {
  children: React.ReactNode;
  initialData?: Partial<InitialEditFormValues>; // Datos iniciales opcionales
}

// Valor predeterminado para formData
const defaultFormData: EditFormValues = {
  firstName: '',
  initial: '',
  maidenName: '',
  lastName: '',
  dateOfBirth: '',
  last4SN: '',
  playingPositions: [],
  uniformNumber: '',
  city: '',
  state: '',
  country: '',
  graduationYear: '',
  graduationYearOption: '',
  currentSchool: '',
  aimingColleges: [],
  weight: '',
  height: '',
  throwingHand: '',
  battingHand: '',
  legalWaiver: false,
  selectedPlan: '',
};

// Crear el contexto sin posibilidad de null
export const EditPlayerFormContext = createContext<EditPlayerFormContextProps>({
  formData: defaultFormData,
  setFormData: () => {},
});

export const EditPlayerFormProvider = ({
  children,
  initialData = {},
}: EditPlayerFormProviderProps) => {
  // Transformar campos específicos si vienen como cadenas en lugar de arrays
  const transformInitialData = (data: Partial<InitialEditFormValues>): EditFormValues => {
    return {
      ...defaultFormData,
      ...data,
      playingPositions: data.playingPositions
        ? Array.isArray(data.playingPositions)
          ? data.playingPositions
          : data.playingPositions.split(', ').map((pos) => pos.trim())
        : [],
      aimingColleges: data.aimingColleges
        ? Array.isArray(data.aimingColleges)
          ? data.aimingColleges
          : data.aimingColleges.split(', ').map((college) => college.trim())
        : [],
    };
  };

  // Inicializar formData con los datos transformados
  const [formData, setFormData] = useState<EditFormValues>(
    transformInitialData(initialData)
  );

  // Opcional: Para depuración, puedes usar un useEffect para verificar formData
  useEffect(() => {
    console.log('Initialized formData:', formData);
  }, [formData]);

  return (
    <EditPlayerFormContext.Provider value={{ formData, setFormData }}>
      {children}
    </EditPlayerFormContext.Provider>
  );
};
