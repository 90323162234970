import React, { useContext } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Text,
  List,
  ListItem,
  Icon,
  Image,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { EditPlayerFormContext } from '../context/EditPlayerFormContext';
import WizardButton from '../components/Shared/WizardButton';

interface UpgradePlayerStep2Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const UpgradePlayerStep2: React.FC<UpgradePlayerStep2Props> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const { formData, setFormData } = useContext(EditPlayerFormContext)!;

  const validationSchema = Yup.object({
    selectedPlan: Yup.string()
      .oneOf(['insurance', 'noInsurance'], 'Please select an option')
      .required('Selection is required'),
  });

  const formik = useFormik({
    initialValues: {
      selectedPlan: formData.selectedPlan || '', // Use existing data or default to empty
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormData({ ...formData, selectedPlan: values.selectedPlan });
      handleNextStep();
    },
  });

  const handleBack = () => {
    formik.setTouched({});
    handlePreviousStep();
  };

  return (
    <Box bg="white" p={6} borderRadius="md" boxShadow="md" maxW="100%" mx="auto">
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6}>
          <FormControl
            isInvalid={!!formik.errors.selectedPlan && formik.touched.selectedPlan}
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={6}
              justify="center"
              align="stretch"
            >
              <Flex
                direction="column"
                align="center"
                p={4}
                borderWidth="1px"
                borderRadius="md"
                cursor="pointer"
                bg={formik.values.selectedPlan === 'insurance' ? 'gray.100' : 'white'}
                boxShadow={formik.values.selectedPlan === 'insurance' ? 'lg' : 'md'}
                onClick={() => formik.setFieldValue('selectedPlan', 'insurance')}
                position="relative"
                width={{ base: '100%', md: '300px' }}
                height={{ base: 'auto', md: '400px' }}
                transition="all 0.2s"
                overflow="hidden"
              >
                <Icon
                  as={formik.values.selectedPlan === 'insurance' ? FaCheckCircle : FaRegCircle}
                  color={formik.values.selectedPlan === 'insurance' ? '#1B154B' : 'gray.300'}
                  boxSize={6}
                  position="absolute"
                  top="2"
                  right="2"
                />
                <Flex justify="center" align="center" width="100%" height="100%">
                  <Image
                    src="/images/Multinational_Logo_2022.png"
                    alt="Insurance Company Logo"
                    objectFit="contain"
                    width="90%"
                    height="90%"
                  />
                </Flex>
                <Heading as="h3" size="md" mb={2} textAlign="center">
                  Insurance Plan
                </Heading>
                <Text mb={4} textAlign="center" fontSize="sm">
                  Can insure player for the following:
                </Text>
                <List spacing={1} textAlign="left" mb={4} fontSize="sm">
                  <ListItem>- Career Ending Injuries</ListItem>
                  <ListItem>- Weather Calamities</ListItem>
                  <ListItem>- Loss of Parent</ListItem>
                  <ListItem>- Stolen Equipment</ListItem>
                  <ListItem>- Reimbursement Of Sport Trips</ListItem>
                </List>
                <Text fontWeight="bold" fontSize="md">
                  $250.00 per year
                </Text>
              </Flex>

              <Flex
                direction="column"
                align="center"
                p={4}
                borderWidth="1px"
                borderRadius="md"
                cursor="pointer"
                bg={formik.values.selectedPlan === 'noInsurance' ? 'gray.100' : 'white'}
                boxShadow={formik.values.selectedPlan === 'noInsurance' ? 'lg' : 'md'}
                onClick={() => formik.setFieldValue('selectedPlan', 'noInsurance')}
                position="relative"
                width={{ base: '100%', md: '300px' }}
                height={{ base: 'auto', md: '400px' }}
                transition="all 0.2s"
                overflow="hidden"
              >
                <Icon
                  as={formik.values.selectedPlan === 'noInsurance' ? FaCheckCircle : FaRegCircle}
                  color={formik.values.selectedPlan === 'noInsurance' ? '#1B154B' : 'gray.300'}
                  boxSize={6}
                  position="absolute"
                  top="2"
                  right="2"
                />
                <Heading as="h3" size="md" mb={2} textAlign="center">
                  No Insurance Plan
                </Heading>
                <Text
                  textAlign="center"
                  mb={4}
                  fontSize="sm"
                  noOfLines={2}
                  maxWidth="100%"
                >
                  I choose not to add the insurance plan to my player profile.
                </Text>
                <Text fontWeight="bold" fontSize="md" mt="auto">
                  Free
                </Text>
              </Flex>
            </Flex>
            <FormErrorMessage>{formik.errors.selectedPlan}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Flex justify="flex-end" mt={8} gap={4}>
          <WizardButton onClick={handleBack} variant="back" isLoading={formik.isSubmitting}>
            Back
          </WizardButton>
          <WizardButton
            type="submit"
            variant="next"
            isDisabled={!formik.values.selectedPlan}
            isLoading={formik.isSubmitting}
          >
            Next
          </WizardButton>
        </Flex>
      </form>
    </Box>
  );
};

export default UpgradePlayerStep2;
