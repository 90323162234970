// src/pages/CreatePlayerStep1.tsx

import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormErrorMessage,
  Flex,
  Heading,
  List,
  ListItem,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  useToast,
  Link,
  Icon,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country, State, City, ICountry } from 'country-state-city';
import ReactCountryFlag from 'react-country-flag';
import { FaFilePdf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { PlayerFormContext } from '../context/PlayerFormContext';
import { schoolOptions, collegeOptions, playingPositionsOptions, PRCityOptions } from '../constants/options';
import MaskedInput from '../components/Shared/MaskedInput';
import WizardButton from '../components/Shared/WizardButton';

interface FormValues {
  firstName: string;
  initial: string;
  maidenName: string;
  lastName: string;
  dateOfBirth: string;
  playingPositions: string[];
  uniformNumber: string;
  city: string;
  state: string;
  country: string;
  graduationYear: string;
  graduationYearOption: string;
  currentSchool: string;
  last4SN: string;
  aimingColleges: string[];
  weight: string;
  heightFeet: string;
  heightInches: string; 
  throwingHand: string;
  battingHand: string;
  legalWaiver: boolean;
}

interface CountryOption {
  value: string;
  label: string;
}

interface CreatePlayerStep1Props {
  handleNextStep: () => void;
}

const CreatePlayerStep1: React.FC<CreatePlayerStep1Props> = ({ handleNextStep }) => {

  const navigate = useNavigate(); // Para redirigir a la creación de jugadores

  const toMyRoster = () => {
    navigate('/my-roster');
  };

  const { formData, setFormData } = useContext(PlayerFormContext)!;

  const [aimingCollegeInput, setAimingCollegeInput] = useState<string>('');
  const [aimingCollegeSuggestions, setAimingCollegeSuggestions] = useState<string[]>([]);
  const aimingCollegeInputRef = useRef<HTMLInputElement>(null);

  const [playingPositionInput, setPlayingPositionInput] = useState<string>('');
  const [playingPositionSuggestions, setPlayingPositionSuggestions] = useState<string[]>([]);
  const playingPositionInputRef = useRef<HTMLInputElement>(null);

  const toast = useToast();

  const currentYear = new Date().getFullYear();
  const graduationYearOptions = Array.from({ length: 26 }, (_, i) => (currentYear + i).toString());

  // Función para parsear el height existente en feet e inches
  const parseHeight = (height: string): { feet: string; inches: string } => {
    const match = height.match(/^(\d+)'(\d+)"$/);
    if (match) {
      return { feet: match[1], inches: match[2] };
    }
    return { feet: '', inches: '' };
  };

  const initialHeight = formData.height ? parseHeight(formData.height) : { feet: '', inches: '' };

  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: formData.firstName || '',
      initial: formData.initial || '',
      maidenName: formData.maidenName || '',
      lastName: formData.lastName || '',
      dateOfBirth: formData.dateOfBirth || '',
      playingPositions: formData.playingPositions || [],
      uniformNumber: formData.uniformNumber || '',
      city: formData.city || '',
      state: formData.state || '',
      country: formData.country || '',
      graduationYear: formData.graduationYear || '',
      graduationYearOption: formData.graduationYearOption || '',
      last4SN: formData.last4SN || '',
      currentSchool: formData.currentSchool || '',
      aimingColleges: formData.aimingColleges || [],
      weight: formData.weight || '',
      heightFeet: initialHeight.feet, 
      heightInches: initialHeight.inches, 
      throwingHand: formData.throwingHand || '',
      battingHand: formData.battingHand || '', 
      legalWaiver: formData.legalWaiver || false, 
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      initial: Yup.string()
      .matches(/^[A-Za-z]$/, 'Initial must be a single letter'),
      maidenName: Yup.string(),
      lastName: Yup.string().required('Last Name is required'),
      dateOfBirth: Yup.date()
        .required('Date of Birth is required')
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 3)),
          'Player must be at least 3 years old'
        )
        .min(
          new Date(new Date().setFullYear(new Date().getFullYear() - 26)), 
          'Player must be at most 25 years old'
        ),
      playingPositions: Yup.array()
        .of(Yup.string().required('Invalid Playing Position'))
        .min(1, 'Please select at least one Playing Position')
        .max(3, 'You can select up to 3 Playing Positions')
        .required('Playing Positions are required'),
      uniformNumber: Yup.string()
        .matches(/^\d{1,3}$/, 'Uniform Number must be 1 to 3 digits')
        .required('Uniform Number is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      country: Yup.string().required('Country is required'),
      graduationYearOption: Yup.string().required('Graduation Year selection is required'),
      graduationYear: Yup.string()
        .matches(/^\d{4}$/, 'Graduation Year must be exactly 4 digits')
        .required('Graduation Year is required'),
      currentSchool: Yup.string().required('Current School is required'),
      last4SN: Yup.string()
      .matches(/^\d{4}$/, 'Enter the last 4 digits of your SSN')
      .required('Last 4 SSN is required'),
      aimingColleges: Yup.array()
        .of(Yup.string().required('Aiming College cannot be empty'))
        .max(3, 'You can select up to 3 Aiming Colleges'),
      weight: Yup.number()
        .typeError('Weight must be a number')
        .max(500, 'Weight must be at most 500 lbs')
        .required('Weight is required'),
      heightFeet: Yup.string()
        .required('Feet is required')
        .matches(/^[2-8]$/, 'Feet must be between 2 and 8'),
      heightInches: Yup.string()
        .required('Inches is required')
        .matches(/^(?:0|[1-9]|10|11)$/, 'Inches must be between 0 and 11'),
      throwingHand: Yup.string().required('Throwing Hand is required'),
      battingHand: Yup.string().required('Batting Hand is required'),
      legalWaiver: Yup.boolean()
        .oneOf([true], 'You must acknowledge the legal waiver')
        .required('You must acknowledge the legal waiver'),
    }),
    onSubmit: (values) => {
      // Concatenar heightFeet y heightInches para formar height
      const height = `${values.heightFeet}'${values.heightInches}"`;
      setFormData({ ...formData, ...values, height });
      handleNextStep();
    },
    validateOnMount: true,
  });

  const handleAimingCollegeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setAimingCollegeInput(input);

    if (input.length > 0) {
      const filtered = collegeOptions.filter((college: string) =>
        college.toLowerCase().includes(input.toLowerCase())
      );
      setAimingCollegeSuggestions(filtered);
    } else {
      setAimingCollegeSuggestions([]);
    }
  };
  
  const addAimingCollege = (college: string) => {
    if (
      college.trim() !== '' &&
      !formik.values.aimingColleges.includes(college) &&
      formik.values.aimingColleges.length < 3 &&
      collegeOptions.includes(college)
    ) {
      formik.setFieldValue('aimingColleges', [
        ...formik.values.aimingColleges,
        college,
      ]);
      formik.setFieldTouched('aimingColleges', true); 
      setAimingCollegeInput('');
      setAimingCollegeSuggestions([]);
      if (aimingCollegeInputRef.current) {
        aimingCollegeInputRef.current.focus();
      }
    } else if (formik.values.aimingColleges.length >= 3) {
      toast({
        title: 'Limit Reached',
        description: 'You can select up to 3 aiming colleges.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    } else if (!collegeOptions.includes(college)) {
      toast({
        title: 'Invalid College',
        description: 'Please select a valid aiming college.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const removeAimingCollege = (college: string) => {
    const updatedColleges = formik.values.aimingColleges.filter(
      (c: string) => c !== college
    );
    formik.setFieldValue('aimingColleges', updatedColleges);
    formik.setFieldTouched('aimingColleges', true); 
  };

  const handlePlayingPositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPlayingPositionInput(inputValue);

    if (inputValue.length > 0) {
      const filteredPositions = playingPositionsOptions.filter((position: string) =>
        position.toLowerCase().includes(inputValue.toLowerCase())
      );
      setPlayingPositionSuggestions(filteredPositions);
    } else {
      setPlayingPositionSuggestions([]);
    }
  };

  const addPlayingPositionHandler = async (position: string) => {
    if (
      position.trim() !== '' &&
      !formik.values.playingPositions.includes(position) &&
      formik.values.playingPositions.length < 3 &&
      playingPositionsOptions.includes(position)
    ) {
        await formik.setFieldValue('playingPositions', [
          ...formik.values.playingPositions,
          position,
        ]);
        await formik.setFieldTouched('playingPositions', true);
        setPlayingPositionInput('');
        setPlayingPositionSuggestions([]);
        if (playingPositionInputRef.current) {
          playingPositionInputRef.current.focus();
        }
      } else if (formik.values.playingPositions.length >= 3) {
        toast({
          title: 'Limit Reached',
          description: 'You can select up to 3 playing positions.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
    } else if (!playingPositionsOptions.includes(position)) {
      toast({
        title: 'Invalid Position',
        description: 'Please select a valid playing position.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const removePlayingPosition = async (position: string) => {
    const updatedPositions = formik.values.playingPositions.filter(
      (p: string) => p !== position
    );
    await formik.setFieldValue('playingPositions', updatedPositions);
    await formik.setFieldTouched('playingPositions', true); 
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        aimingCollegeInputRef.current &&
        !aimingCollegeInputRef.current.contains(event.target as Node)
      ) {
        setAimingCollegeSuggestions([]);
      }
      if (
        playingPositionInputRef.current &&
        !playingPositionInputRef.current.contains(event.target as Node)
      ) {
        setPlayingPositionSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleGraduationYearSelect = (selectedOption: any) => {
    formik.setFieldValue('graduationYearOption', selectedOption.value);
    if (selectedOption.value !== 'Other') {
      formik.setFieldValue('graduationYear', selectedOption.value);
    } else {
      formik.setFieldValue('graduationYear', '');
    }
  };

  // Obtener lista de países y transformar para react-select, excluyendo Puerto Rico
  const countries: ICountry[] = Country.getAllCountries().filter(country => country.isoCode !== 'PR');

  const countryOptions: CountryOption[] = countries.map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  // Obtener lista de estados si el país está seleccionado
  const statesList =
    formik.values.country ? State.getStatesOfCountry(formik.values.country) : [];

  // Obtener lista de ciudades si el estado está seleccionado
  const citiesList =
    formik.values.country && formik.values.state
      ? (formik.values.country === 'US' && formik.values.state === 'PR'
          ? PRCityOptions // Usar PRCityOptions
          : City.getCitiesOfState(formik.values.country, formik.values.state))
      : [];

  // Opciones para los estados en react-select
  const stateOptions = statesList.map((state) => ({
    value: state.isoCode,
    label: state.name,
  }));

  // Opciones para las ciudades en react-select
  const cityOptions = citiesList.map((city: string | any) => ({
    value: typeof city === 'string' ? city : city.name,
    label: typeof city === 'string' ? city : city.name,
  }));

  // Opciones para Feet (2-8)
  const feetOptions = Array.from({ length: 7 }, (_, i) => ({
    value: (i + 2).toString(),
    label: (i + 2).toString(),
  }));

  // Opciones para Inches (0-11)
  const inchesOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i.toString(),
    label: i.toString(),
  }));

  // Definir opciones para los campos de selección que no requieren banderas o estilos avanzados
  const simpleSelectOptions = [
    { value: 'Right', label: 'Right' },
    { value: 'Left', label: 'Left' },
    { value: 'Both', label: 'Both' },
  ];

  // Opciones para Current School
  const schoolSelectOptions = schoolOptions.map((school) => ({
    value: school,
    label: school,
  }));

  // Manejadores para Country, State y City
  const handleCountrySelect = (selectedOption: any) => {
    if (selectedOption) {
      formik.setFieldValue('country', selectedOption.value);
      // Resetear State y City al cambiar el país
      formik.setFieldValue('state', '');
      formik.setFieldValue('city', '');
    } else {
      formik.setFieldValue('country', '');
      formik.setFieldValue('state', '');
      formik.setFieldValue('city', '');
    }
  };

  const handleStateSelect = (selectedOption: any) => {
    if (selectedOption) {
      formik.setFieldValue('state', selectedOption.value);
      formik.setFieldValue('city', '');
    } else {
      formik.setFieldValue('state', '');
      formik.setFieldValue('city', '');
    }
  };

  const handleCitySelect = (selectedOption: any) => {
    if (selectedOption) {
      formik.setFieldValue('city', selectedOption.value);
    } else {
      formik.setFieldValue('city', '');
    }
  };

  // Opcional: Añadir un console.log para depuración
  useEffect(() => {
    console.log('Playing Positions:', formik.values.playingPositions);
    console.log('Aiming Colleges:', formik.values.aimingColleges);
  }, [formik.values.playingPositions, formik.values.aimingColleges]);

  return (
    <Box
      bg="white"
      p={8}
      borderRadius="md"
      boxShadow="lg"
      maxW="100%" 
      mx="auto" // Centrado horizontalmente
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Box width={{ base: '100%', md: '33.33%' }}>
              <Stack direction="row" spacing={2} align="flex-start">
                <Box flex="1">
                  <FormControl
                    isRequired
                    isInvalid={
                      formik.touched.firstName && Boolean(formik.errors.firstName)
                    }
                  >
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="firstName"
                      placeholder="Enter first name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                  </FormControl>
                </Box>

                <Box flex="0 0 60px">
                  <FormControl
                    isInvalid={
                      formik.touched.initial && Boolean(formik.errors.initial)
                    }
                  >
                    <FormLabel>Initial</FormLabel>
                    <Input
                      name="initial"
                      placeholder="I"
                      value={formik.values.initial}
                      onBlur={formik.handleBlur}
                      maxLength={1}
                      width="60px"
                      onChange={(e) => {
                        const value = e.target.value;
                        // Permitir solo letras y limitar a 1 carácter
                        if (/^[A-Za-z]{0,1}$/.test(value)) {
                          formik.handleChange(e);
                        }
                      }}
                      textTransform="uppercase"
                    />
                    <FormErrorMessage>{formik.errors.initial}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              >
                <FormLabel>Last Name</FormLabel>
                <Input
                  name="lastName"
                  placeholder="Enter last name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isInvalid={
                  formik.touched.maidenName && Boolean(formik.errors.maidenName)
                }
              >
                <FormLabel>Maiden Name</FormLabel>
                <Input
                  name="maidenName"
                  placeholder="Enter maiden name"
                  value={formik.values.maidenName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>{formik.errors.maidenName}</FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>


          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.last4SN && Boolean(formik.errors.last4SN)
                }
              >
                <FormLabel>Last 4 SSN</FormLabel>
                <Input
                  type="text"
                  name="last4SN"
                  placeholder="Enter last 4 digits of SSN"
                  value={formik.values.last4SN}
                  onChange={formik.handleChange}
                  maxLength={4}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                      e.preventDefault();
                    }
                  }}
                />
                <FormErrorMessage>{formik.errors.last4SN}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
                }
              >
                <FormLabel>Date of Birth</FormLabel>
                <Input
                  type="date"
                  name="dateOfBirth"
                  placeholder="Select date of birth"
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>{formik.errors.dateOfBirth}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isInvalid={
                  formik.touched.playingPositions &&
                  Boolean(formik.errors.playingPositions)
                }
              >
                <FormLabel>Playing Positions
                  <Text as="span" color="red.500" fontSize="lg" ml="1" >
                    *
                  </Text>
                </FormLabel>
                <Box position="relative">
                  <Input
                    name="playingPositionInput"
                    placeholder="Select playing position"
                    value={playingPositionInput}
                    onChange={handlePlayingPositionChange}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addPlayingPositionHandler(playingPositionInput);
                      }
                    }}
                    ref={playingPositionInputRef}
                    autoComplete="off"
                  />
                  {playingPositionSuggestions.length > 0 && (
                    <Box
                      position="absolute"
                      top="100%"
                      left="0"
                      right="0"
                      bg="white"
                      border="1px solid gray.100" 
                      borderRadius="md"
                      maxH="200px"
                      overflowY="auto"
                      zIndex="1"
                    >
                      <List spacing={1}>
                        {playingPositionSuggestions.map((position: string, index: number) => (
                          <ListItem
                            key={index}
                            px={4}
                            py={2}
                            _hover={{ bg: '#2C5282', color: 'white', cursor: 'pointer' }} 
                            onMouseDown={() => addPlayingPositionHandler(position)} 
                          >
                            {position}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>
                <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                  {formik.values.playingPositions.map((position: string, index: number) => (
                    <Tag
                      size="md"
                      key={index}
                      borderRadius="full"
                      variant="solid"
                      bg="#09264a" 
                      color="white" 
                    >
                      <TagLabel>{position}</TagLabel>
                      <TagCloseButton onClick={() => removePlayingPosition(position)} />
                    </Tag>
                  ))}
                </Box>
                <FormErrorMessage>
                  {formik.errors.playingPositions as string}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.uniformNumber && Boolean(formik.errors.uniformNumber)
                }
              >
                <FormLabel>Uniform Number</FormLabel>
                <Input
                  type="text"
                  name="uniformNumber"
                  placeholder="Enter uniform number"
                  value={formik.values.uniformNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,3}$/.test(value)) {
                      formik.setFieldValue("uniformNumber", value);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  maxLength={3}
                />
                <FormErrorMessage>{formik.errors.uniformNumber}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={formik.touched.country && Boolean(formik.errors.country)}
              >
                <FormLabel>Country</FormLabel>
                <Select
                  options={countryOptions}
                  placeholder="Select country"
                  onChange={handleCountrySelect}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.country
                      ? countryOptions.find(
                          (option) => option.value === formik.values.country
                        )
                      : null
                  }
                  isSearchable
                  formatOptionLabel={(option: CountryOption) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ReactCountryFlag
                        countryCode={option.value}
                        svg
                        style={{
                          width: '1.2em',
                          height: '1.2em',
                          marginRight: '0.5em',
                        }}
                        title={option.label}
                      />
                      {option.label}
                    </div>
                  )}
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100', 
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white', 
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white', 
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282', 
                    }),
                  }}
                />
                <FormErrorMessage>
                  {formik.errors.country}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              {statesList.length > 0 ? (
                <FormControl
                  isRequired
                  isInvalid={formik.touched.state && Boolean(formik.errors.state)}
                >
                  <FormLabel>State</FormLabel>
                  <Select
                    options={stateOptions}
                    placeholder="Select state"
                    onChange={handleStateSelect}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.state
                        ? stateOptions.find((option) => option.value === formik.values.state)
                        : null
                    }
                    isSearchable
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        borderColor: 'gray.100', 
                        '&:hover': { borderColor: 'gray.100' }, 
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        borderColor: 'gray.100',
                        backgroundColor: 'white', 
                      }),
                      option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#2C5282' : 'white', 
                        color: state.isFocused ? 'white' : '#2C5282',
                        cursor: 'pointer',
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        color: '#2C5282', 
                      }),
                    }}
                  />
                  <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
                </FormControl>
              ) : (
                <FormControl
                  isRequired
                  isInvalid={formik.touched.state && Boolean(formik.errors.state)}
                >
                  <FormLabel>State</FormLabel>
                  <Input
                    name="state"
                    placeholder="Enter state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
                </FormControl>
              )}
            </Box>
          </Stack>

          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
              <Box width={{ base: '100%', md: '33.33%' }}>
              {citiesList.length > 0 ? (
                <FormControl
                  isRequired
                  isInvalid={formik.touched.city && Boolean(formik.errors.city)}
                >
                  <FormLabel>City</FormLabel>
                  <Select
                    options={cityOptions}
                    placeholder="Select city"
                    onChange={handleCitySelect}
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.city
                        ? cityOptions.find((option) => option.value === formik.values.city)
                        : null
                    }
                    isDisabled={!formik.values.state}
                    isSearchable
                    styles={{
                      control: (provided: any) => ({
                        ...provided,
                        borderColor: 'gray.100', 
                        '&:hover': { borderColor: 'gray.100' }, 
                      }),
                      menu: (provided: any) => ({
                        ...provided,
                        borderColor: 'gray.100',
                        backgroundColor: 'white', 
                      }),
                      option: (provided: any, state: any) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? '#2C5282' : 'white', 
                        color: state.isFocused ? 'white' : '#2C5282',
                        cursor: 'pointer',
                      }),
                      singleValue: (provided: any) => ({
                        ...provided,
                        color: '#2C5282',
                      }),
                    }}
                  />
                  <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                </FormControl>
              ) : (
                <FormControl
                  isRequired
                  isInvalid={formik.touched.city && Boolean(formik.errors.city)}
                >
                  <FormLabel>City</FormLabel>
                  <Input
                    name="city"
                    placeholder="Enter city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
                </FormControl>
              )}
            </Box>

              <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.currentSchool &&
                  Boolean(formik.errors.currentSchool)
                }
              >
                <FormLabel>Current School</FormLabel>
                <Select
                  name="currentSchool"
                  options={schoolSelectOptions}
                  placeholder="Select current school"
                  value={
                    formik.values.currentSchool
                      ? { value: formik.values.currentSchool, label: formik.values.currentSchool }
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('currentSchool', selectedOption ? selectedOption.value : '');
                  }}
                  onBlur={formik.handleBlur}
                  isClearable
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white',
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white',
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {formik.errors.currentSchool}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.graduationYearOption &&
                  Boolean(formik.errors.graduationYearOption)
                }
              >
                <FormLabel>Graduation Year</FormLabel>
                <Select
                  name="graduationYearOption"
                  options={[
                    ...graduationYearOptions.map((year) => ({ value: year, label: year })),
                    //{ value: 'Other', label: 'Other' },
                  ]}
                  placeholder="Select graduation year"
                  value={
                    formik.values.graduationYearOption
                      ? {
                          value: formik.values.graduationYearOption,
                          label: formik.values.graduationYearOption,
                        }
                      : null
                  }
                  onChange={handleGraduationYearSelect}
                  onBlur={formik.handleBlur}
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white', 
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white', 
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282', // #2C5282
                    }),
                  }}
                />
                {formik.values.graduationYearOption === 'Other' && (
                  <Input
                    mt={2}
                    name="graduationYear"
                    placeholder="Enter graduation year"
                    value={formik.values.graduationYear}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="number"
                    maxLength={4}
                  />
                )}
                <FormErrorMessage>
                  {formik.errors.graduationYear}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isInvalid={
                  formik.touched.aimingColleges &&
                  Boolean(formik.errors.aimingColleges)
                }
              >
                <FormLabel>Aiming Colleges</FormLabel>
                <Box position="relative">
                  <Input
                    name="aimingCollegesInput"
                    placeholder="Enter aiming college"
                    value={aimingCollegeInput}
                    onChange={handleAimingCollegeChange}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addAimingCollege(aimingCollegeInput);
                      }
                    }}
                    ref={aimingCollegeInputRef}
                    autoComplete="off"
                  />
                  {aimingCollegeSuggestions.length > 0 && (
                    <Box
                      position="absolute"
                      top="100%"
                      left="0"
                      right="0"
                      bg="white"
                      border="1px solid gray.100" 
                      borderRadius="md"
                      maxH="150px"
                      overflowY="auto"
                      zIndex="1"
                    >
                      <List spacing={1}>
                        {aimingCollegeSuggestions.map((college: string, index: number) => (
                          <ListItem
                            key={index}
                            px={4}
                            py={2}
                            _hover={{ bg: '#2C5282', color: 'white', cursor: 'pointer' }} 
                            onMouseDown={() => addAimingCollege(college)} 
                          >
                            {college}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Box>
                <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
                  {formik.values.aimingColleges.map((college: string, index: number) => (
                    <Tag
                      size="md"
                      key={index}
                      borderRadius="full"
                      variant="solid"
                      bg="#09264a" 
                      color="white" 
                    >
                      <TagLabel>{college}</TagLabel>
                      <TagCloseButton onClick={() => removeAimingCollege(college)} />
                    </Tag>
                  ))}
                </Box>
                <FormErrorMessage>
                  {formik.errors.aimingColleges}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.throwingHand &&
                  Boolean(formik.errors.throwingHand)
                }
              >
                <FormLabel>Throwing Hand</FormLabel>
                <Select
                  name="throwingHand"
                  options={simpleSelectOptions}
                  placeholder="Select throwing hand"
                  value={
                    formik.values.throwingHand
                      ? { value: formik.values.throwingHand, label: formik.values.throwingHand }
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('throwingHand', selectedOption ? selectedOption.value : '');
                  }}
                  onBlur={formik.handleBlur}
                  isClearable
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white',
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white',
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {formik.errors.throwingHand}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.battingHand &&
                  Boolean(formik.errors.battingHand)
                }
              >
                <FormLabel>Batting Hand</FormLabel>
                <Select
                  name="battingHand"
                  options={simpleSelectOptions}
                  placeholder="Select batting hand"
                  value={
                    formik.values.battingHand
                      ? { value: formik.values.battingHand, label: formik.values.battingHand }
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('battingHand', selectedOption ? selectedOption.value : '');
                  }}
                  onBlur={formik.handleBlur}
                  isClearable
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white',
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white',
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {formik.errors.battingHand}
                </FormErrorMessage>
              </FormControl>
            </Box>

            </Stack>

            <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
              <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.weight && Boolean(formik.errors.weight)
                }
              >
                <FormLabel>Weight (lbs)</FormLabel>
                <Input
                  name="weight"
                  type="number"
                  placeholder="Enter weight"
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>
                  {formik.errors.weight}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.heightFeet && Boolean(formik.errors.heightFeet)
                }
              >
                <FormLabel>Height (Feet)</FormLabel>
                <Select
                  name="heightFeet"
                  options={feetOptions}
                  placeholder="Feet"
                  value={
                    formik.values.heightFeet
                      ? feetOptions.find(
                          (option) => option.value === formik.values.heightFeet
                        )
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('heightFeet', selectedOption ? selectedOption.value : '');
                  }}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                      e.preventDefault();
                    }
                  }}
                  isClearable
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white',
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white',
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {formik.errors.heightFeet}
                </FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={{ base: '100%', md: '33.33%' }}>
              <FormControl
                isRequired
                isInvalid={
                  formik.touched.heightInches && Boolean(formik.errors.heightInches)
                }
              >
                <FormLabel>Height (Inches)</FormLabel>
                <Select
                  name="heightInches"
                  options={inchesOptions}
                  placeholder="Inches"
                  value={
                    formik.values.heightInches
                      ? inchesOptions.find(
                          (option) => option.value === formik.values.heightInches
                        )
                      : null
                  }
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('heightInches', selectedOption ? selectedOption.value : '');
                  }}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                      e.preventDefault();
                    }
                  }}
                  isClearable
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      '&:hover': { borderColor: 'gray.100' },
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderColor: 'gray.100',
                      backgroundColor: 'white',
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? '#2C5282' : 'white',
                      color: state.isFocused ? 'white' : '#2C5282',
                      cursor: 'pointer',
                    }),
                    singleValue: (provided: any) => ({
                      ...provided,
                      color: '#2C5282',
                    }),
                  }}
                />
                <FormErrorMessage>
                  {formik.errors.heightInches}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>

          <FormControl
            isInvalid={
              formik.touched.legalWaiver && Boolean(formik.errors.legalWaiver)
            }
          >
            <Checkbox
              name="legalWaiver"
              isChecked={formik.values.legalWaiver}
              onChange={formik.handleChange}
            >
              I acknowledge the{' '}
              <Link
                color="#09264a" // Cambiar a azul índigo
                href="https://cdn.prod.website-files.com/669ad9fffb20abdbb291b6f5/670456eccc72fc72bf21a987_Sample-Terms-and-Conditions-Template_PDF-4.pdf"
                isExternal
              >
                terms and conditions
                <Icon as={FaFilePdf} mx="2px" />
              </Link>{' '}
              that the data gathered of the player will be owned by Stats and companies affiliated with Stats.
            </Checkbox>
            <FormErrorMessage>
              {formik.errors.legalWaiver}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        <Flex justify="flex-end" mt={8} gap={4}>
          <WizardButton
            onClick={toMyRoster}
            variant="back"
          >
            Back
          </WizardButton>
          <WizardButton
            type="submit"
            variant="next"
            isDisabled={!formik.isValid}
          >
            Next
          </WizardButton>
        </Flex>
      </form>
    </Box>
  );
};

export default CreatePlayerStep1;