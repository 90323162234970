import React, { useState } from "react";
import { UserList } from "../components/common/UserList";
import { NewUserCreate } from "../components/common/NewUser";
import { Box } from "@chakra-ui/react";

const Accounts: React.FC = () => {
  const [newUser, setNewUser] = useState(false);

  return (
    <Box
      maxWidth="1200px" // Set a reasonable maximum width
      margin="0 auto" // Center the div horizontally
      paddingX={{ base: "4", md: "8" }} // Responsive horizontal padding
      paddingY="4" // Add vertical padding
    >
      {newUser ? (
        <NewUserCreate newUser={newUser} setNewUser={setNewUser} />
      ) : (
        <UserList newUser={newUser} setNewUser={setNewUser} />
      )}
    </Box>
  );
};

export default Accounts;
