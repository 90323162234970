import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Input,
    Table,
    Tbody,
    Tr,
    Td,
    Flex,
    Heading,
    TableContainer,
    InputGroup,
    InputLeftElement,
    Skeleton,
    Text,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
} from "@chakra-ui/react";
import { SearchIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import ViewPlayerModal from "../components/players_registry/ViewPlayerModal";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosConfig";
import ReactCountryFlag from "react-country-flag";
import { playingPositionsOptions } from "../constants/options";
import Select from "react-select";

interface Player {
    PLAYER_ID: string;
    FIRST_NAME: string;
    LAST_NAME: string;
    HEIGHT: string;
    WEIGHT: string;
    PLAYING_POSITIONS: string | null;
    CITY: string;
    STATE: string;
    COUNTRY: string;
    DATE_OF_BIRTH: string;
    CREATED_AT: string;
    STATUS: string;
}

const SHOW_ONLY_COMPLETE = false;
const PLAYERS_PER_PAGE = 25;

const calculateAge = (dateOfBirth: string) => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

const SearchPlayers: React.FC = () => {
    const navigate = useNavigate();
    const [players, setPlayers] = useState<Player[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredPlayers, setFilteredPlayers] = useState<Player[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPosition, setSelectedPosition] = useState<{ value: string, label: string } | null>({ value: "all", label: "All Positions" });
    const [ageRange, setAgeRange] = useState<[number, number]>([3, 25]);
    const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null);
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

    const positionOptions = [{ value: "all", label: "All Positions" }, ...playingPositionsOptions.map(position => ({
        value: position.split(" - ")[0],
        label: position,
    }))];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get<{ success: boolean; players: Player[] }>('/players');
                if (response.data.success) {
                    let playersData = response.data.players;
    
                    if (SHOW_ONLY_COMPLETE) {
                        playersData = playersData.filter(player =>
                            player.PLAYER_ID && player.FIRST_NAME && player.LAST_NAME &&
                            player.HEIGHT && player.WEIGHT && player.PLAYING_POSITIONS &&
                            player.CITY && player.STATE && player.DATE_OF_BIRTH && player.COUNTRY
                        );
                    }
    
                    // Ordena los jugadores en orden descendente según CREATED_AT
                    playersData.sort((a, b) => new Date(b.CREATED_AT).getTime() - new Date(a.CREATED_AT).getTime());
    
                    setPlayers(playersData);
                    setFilteredPlayers(playersData);
                } else {
                    console.error('Error en la respuesta:', response.data);
                }
            } catch (error) {
                console.error('Error al obtener jugadores:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);
    

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCurrentPage(1);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const timeout = setTimeout(() => {
            applyFilters(value, selectedPosition, ageRange);
        }, 300);

        setSearchTimeout(timeout);
    };

    const handlePositionChange = (selectedOption: { value: string, label: string } | null) => {
        setSelectedPosition(selectedOption);
        applyFilters(searchTerm, selectedOption, ageRange);
    };

    const handleAgeRangeChange = (value: [number, number]) => {
        setAgeRange(value);
        applyFilters(searchTerm, selectedPosition, value);
    };

    const applyFilters = (search: string, position: { value: string, label: string } | null, ageRange: [number, number]) => {
        const [minAge, maxAge] = ageRange;
        const isFullRange = minAge === 3 && maxAge === 25; 
    
        const filtered = players.filter((player) => {
            const matchesSearchTerm = [
                player.FIRST_NAME,
                player.LAST_NAME,
                player.PLAYING_POSITIONS,
                player.CITY,
                player.STATE,
                player.COUNTRY,
                player.DATE_OF_BIRTH,
                player.HEIGHT,
                player.WEIGHT,
                player.STATUS,
                player.PLAYER_ID,            ]
                .join(" ")
                .toLowerCase()
                .includes(search.toLowerCase());
    

                const matchesPosition =
                    !position || position.value === "all" || 
                    (player.PLAYING_POSITIONS && player.PLAYING_POSITIONS
                        .split(', ') // Divide las posiciones por coma y espacio
                        .some(pos => pos.split(' - ')[0] === position.value));
    
            const playerAge = player.DATE_OF_BIRTH ? calculateAge(player.DATE_OF_BIRTH) : null;
            const matchesAgeRange = isFullRange || (playerAge !== null && playerAge >= minAge && playerAge <= maxAge);
    
            return matchesSearchTerm && matchesPosition && matchesAgeRange;
        });
    
        setFilteredPlayers(filtered);
    };
    
    

    const totalPages = Math.ceil(filteredPlayers.length / PLAYERS_PER_PAGE);
    const paginatedPlayers = filteredPlayers.slice(
        (currentPage - 1) * PLAYERS_PER_PAGE,
        currentPage * PLAYERS_PER_PAGE
    );

    const goToPage = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <>
            <Box width="100%" p={2} minHeight="100vh" >
                <Flex gap={2} alignItems={"center"} mb={{ base: 3, md: 7 }}>
                    <Button background={"#fff"} onClick={() => navigate(-1)} width={"40px"}>
                        <ChevronLeftIcon fontSize={"3xl"} />
                    </Button>
                    <Heading as="h3" fontSize={{ base: 18, md: 24, lg: 30, xl: "3xl" }}>
                        {"Search Players"}
                    </Heading>
                </Flex>

                <Flex gap={4} justify="space-between" align="center" mb={6}>
                    <InputGroup maxW={"300px"} minW={{ base: "100%", sm: "150px" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search by any field"
                            color={"#545560"}
                            bg={"white"}
                            border="1px solid gray"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>

                    <Flex align="center" gap={4} ml="auto" mr="20px">
                        <Flex align="center" gap={2}>
                            <Text>Position:</Text>
                            <Box w="220px" border="1px solid gray" borderRadius="md"> 
                            <Select
                                placeholder="Select Position"
                                value={selectedPosition}
                                options={positionOptions}
                                onChange={handlePositionChange}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: "gray.500",
                                        '&:hover': { borderColor: "gray.500" },
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        borderColor: "gray.100",
                                        backgroundColor: "white",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? "#2C5282" : "white",
                                        color: state.isFocused ? "white" : "#2C5282",
                                        cursor: "pointer",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#2C5282",
                                    }),
                                }}
                            />
                            </Box>
                        </Flex>

                        <Flex align="center" gap={4} ml="20px">
                            <Text>Age:</Text>
                            <Box w="150px" mt="20px">
                                <RangeSlider
                                    defaultValue={ageRange}
                                    min={3}
                                    max={25}
                                    step={1}
                                    value={ageRange}
                                    onChange={(val) => setAgeRange(val as [number, number])}
                                    onChangeEnd={handleAgeRangeChange}
                                >
                                    <RangeSliderTrack bg="gray.200">
                                        <RangeSliderFilledTrack bg="#2C5282" />
                                    </RangeSliderTrack>
                                    <RangeSliderThumb boxSize={4} index={0} bg="#2C5282" />
                                    <RangeSliderThumb boxSize={4} index={1} bg="#2C5282" />
                                </RangeSlider>
                                <Flex justify="space-between">
                                    <Text fontSize="sm">{ageRange[0]}</Text>
                                    <Text fontSize="sm">{ageRange[1]}</Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>

                <Box flex="1" overflowY="auto" borderRadius="lg">
                    <TableContainer
                        border="1px solid"
                        borderColor="white"
                        borderRadius="lg"
                        maxHeight="680px"
                        overflowY="auto"
                        maxWidth="100%"
                        sx={{
                            "::-webkit-scrollbar": {
                            width: "6px",
                            },
                            "::-webkit-scrollbar-thumb": {
                            backgroundColor: "gray.500",
                            borderRadius: "8px",
                            },
                            "::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "blue.700",
                            },
                            "::-webkit-scrollbar-track": {
                            backgroundColor: "gray.300",
                            },
                        }}
                        >
                        <Table
                            variant="striped"
                            colorScheme="white"
                            sx={{
                            "tr:nth-of-type(odd)": {
                                backgroundColor: "gray.100", // Color para filas impares
                            },
                            "tr:nth-of-type(even)": {
                                backgroundColor: "white", // Color para filas pares
                            },
                            "tr:hover": {
                                backgroundColor: "gray.200", // Color de hover para cada fila
                            },
                            "td, th": {
                                borderColor: "white", // Color de las líneas divisorias
                            },
                            }}
                        >
                            <Tbody>
                            {loading ? (
                                [...Array(5)].map((_, index) => (
                                    <Tr key={index}>
                                        <Td><Skeleton height="65px" width="65px" /></Td>
                                        <Td><Skeleton height="20px" width="150px" /></Td>
                                        <Td><Skeleton height="20px" width="100px" /></Td>
                                        <Td><Skeleton height="20px" width="100px" /></Td>
                                        <Td><Skeleton height="20px" width="100px" /></Td>
                                        <Td><Skeleton height="20px" width="100px" /></Td>
                                        <Td><Skeleton height="20px" width="150px" /></Td>
                                        <Td><Skeleton height="20px" width="150px" /></Td>
                                    </Tr>
                                ))
                            ) : (
                                paginatedPlayers.map((player, index) => (
                                    <Tr
                                        key={player.PLAYER_ID}
                                        _hover={{ bg: "gray.100" }} 
                                        cursor="pointer"
                                        onClick={() => setSelectedPlayerId(player.PLAYER_ID)}
                                    >
                                        <Td>
                                            <Box
                                                width="65px"
                                                height="65px"
                                                overflow="hidden"
                                                filter={player.STATUS === 'Waif' ? 'grayscale(100%)' : 'none'}
                                            >
                                                <img src="/images/Player Image.png" alt="Player" style={{ width: "100%", height: "100%", objectFit: "cover" }}/>
                                            </Box>
                                        </Td>
                                        <Td>{`${player.FIRST_NAME || "N/A"} ${player.LAST_NAME || "N/A"}`}</Td>
                                        <Td>
                                            <Flex align="center">
                                                {player.STATE === "PR" ? (
                                                    <ReactCountryFlag countryCode="PR" svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                                                ) : (
                                                    <ReactCountryFlag countryCode={player.COUNTRY} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                                                )}
                                                {`${player.CITY || "N/A"}, ${player.STATE || "N/A"}`}
                                            </Flex>
                                        </Td>

                                        <Td>Age: {calculateAge(player.DATE_OF_BIRTH || "N/A")}</Td>
                                        <Td>H: {player.HEIGHT || "N/A"}</Td>
                                        <Td>W: {player.WEIGHT ? `${player.WEIGHT} lbs` : "N/A"}</Td>
                                        <Td>{player.PLAYING_POSITIONS ? player.PLAYING_POSITIONS.split(', ').map(pos => pos.split(' - ')[0]).join(', ') : 'N/A'}</Td>
                                        <Td>
                                            <Button
                                                variant="outline"
                                                color="indigo.500"
                                                borderColor="#1B154B"
                                                size="sm"
                                                width="80px"
                                                _hover={{ bg: "indigo.50" }}
                                            >
                                                {player.PLAYER_ID}
                                            </Button>
                                        </Td>    
                                        <Td>
                                            <Button
                                                variant="outline"
                                                borderColor="#1B154B"
                                                size="sm"
                                                width="80px"
                                                bg={player.STATUS === 'Waif' ? 'gray.300' : 'none'} 
                                                _hover={{
                                                    bg: player.STATUS === 'Waif' ? 'gray.300' : 'none',
                                                }}
                                                >
                                                {player.STATUS}
                                            </Button>
                                        </Td> 
                                    </Tr>
                                ))
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>


                    <Flex justify="center" mt={4} gap={2}>
                        <Text as="span" onClick={() => goToPage(1)} cursor={currentPage === 1 ? "not-allowed" : "pointer"} color={currentPage === 1 ? "gray.400" : "#2C5282"} fontWeight={currentPage === 1 ? "normal" : "bold"}>{"<<"}</Text>
                        <Text as="span" onClick={() => goToPage(currentPage - 1)} cursor={currentPage === 1 ? "not-allowed" : "pointer"} color={currentPage === 1 ? "gray.400" : "#2C5282"} fontWeight={currentPage === 1 ? "normal" : "bold"}>{"<"}</Text>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Text as="span" key={i + 1} onClick={() => goToPage(i + 1)} cursor="pointer" color={currentPage === i + 1 ? "#2C5282" : "gray.700"} fontWeight={currentPage === i + 1 ? "bold" : "normal"}>{i + 1}</Text>
                        ))}
                        <Text as="span" onClick={() => goToPage(currentPage + 1)} cursor={currentPage === totalPages ? "not-allowed" : "pointer"} color={currentPage === totalPages ? "gray.400" : "#2C5282"} fontWeight={currentPage === totalPages ? "normal" : "bold"}>{">"}</Text>
                        <Text as="span" onClick={() => goToPage(totalPages)} cursor={currentPage === totalPages ? "not-allowed" : "pointer"} color={currentPage === totalPages ? "gray.400" : "#2C5282"} fontWeight={currentPage === totalPages ? "normal" : "bold"}>{">>"}</Text>
                    </Flex>
                </Box>

                {selectedPlayerId && (
                    <ViewPlayerModal
                        openViewPlayer={true}
                        playerId={selectedPlayerId}
                        onClose={() => setSelectedPlayerId(null)}
                    />
                )}
            </Box>
        </>
    );
};

export default SearchPlayers;