import React, { ChangeEvent, useState } from "react";

import angleLeft1 from "./NewUser/angle-left-1.svg";
import angleSmallLeft1 from "./NewUser/angle-small-left-1.svg";
import bell1 from "./NewUser/bell-1.svg";
import ellipse1 from "./NewUser/ellipse-1.svg";
import eye1 from "./NewUser/eye-1.svg";
import eyeCrossed1 from "./NewUser/eye-crossed-1.svg";
import frame from "./NewUser/frame.svg";
import image1 from "./NewUser/image-1.svg";
import "./NewUser.css";
import twemojiFlagPuertoRico2 from "./NewUser/twemoji-flag-puerto-rico-2.svg";
import twemojiFlagPuertoRico from "./NewUser/twemoji-flag-puerto-rico.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { createCognitoUser } from "../../store/userSlice";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { sendManagerEmailUponCreation } from "../../store/authSlice";
import { formatDateString } from "../../utils/helpers";

interface NewUserProps {
  newUser: boolean;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewUserCreate = ({
  newUser,
  setNewUser,
}: NewUserProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, success, error } = useSelector(
    (state: RootState) => state.user
  );
  const [formData, setFormData] = useState({
    firstName: "",
    initial: "",
    lastName: "",
    gender: "Male",
    mobileNumber: "",
    dob: "",
    email: "",
    confirmEmail: "",
    role: "Manager",
    zipCode: "",
    password: "",
    confirmPassword: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setNewUser(false); // Optional: Close the form after modal confirmation
  };

  const handleDateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const input = e.target.value;
    const formattedDate = formatDateString(input);

    // Only allow up to MM-DD-YYYY format (10 characters)
    if (formattedDate.length <= 10) {
      setFormData((prev) => ({ ...prev, dob: formattedDate }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    if (id === "dob") {
      handleDateChange(e);
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate email and password match
    if (formData.email !== formData.confirmEmail) {
      alert("Emails do not match!");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Dispatch the createCognitoUser thunk
    const userPayload = {
      address1: formData.addressLine1,
      address2: formData.addressLine2,
      city: formData.city,
      state: formData.state,
      zipCode: formData.zipCode,
      dateOfBirth: formData.dob,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      initial: formData.initial,
      telephoneNumber: `+1${formData.mobileNumber}`,
      gender: formData.gender,
      role: formData.role,
      password: formData.password,
    };

    const result = await dispatch(createCognitoUser(userPayload)); // Dispatch the thunk

    console.log("result", result);

    // Handle success based on Redux state
    if (result.meta.requestStatus === "fulfilled") {
      if (formData.role === "Manager") {
        dispatch(
          sendManagerEmailUponCreation({
            email: formData.email,
            name: `${formData.firstName} ${formData.lastName}`,
            role: formData.role,
            password: formData.password,
          })
        );
      }
      setIsModalOpen(true); // Open success modal
      // Clear the form data and show the success modal
      setFormData({
        firstName: "",
        initial: "",
        lastName: "",
        gender: "Male",
        mobileNumber: "",
        dob: "",
        email: "",
        confirmEmail: "",
        role: "Manager",
        zipCode: "",
        password: "",
        confirmPassword: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
      });
    }
  };

  return (
    <div className="new-user-create">
      <div className="container">
        {/* <header className="header">
          <div className="header-left">
            <div className="back-button">
              <img className="angle-left" alt="Back" src={image1} />
            </div>
            <div className="page-title">Accounts</div>
          </div>
          <div className="header-right">
            <div className="notification">
              <img className="bell" alt="Notifications" src={bell1} />
              <div className="notification-count">5</div>
            </div>
            <div className="user-info">
              <img className="user-avatar" alt="User" src={ellipse1} />
              <div className="user-details">
                <div className="user-name">Allie Gretter</div>
                <div className="user-role">Manager</div>
              </div>
              <img className="dropdown-icon" alt="Dropdown" src={angleLeft1} />
            </div>
          </div>
        </header> */}

        <div className="form-container">
          <h1 className="form-title">New User</h1>
          <form className="user-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name*</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="initial">Initial*</label>
                <input
                  type="text"
                  id="initial"
                  placeholder="Initial"
                  value={formData.initial}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name*</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="gender">Gender*</label>
                <select
                  id="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile Number*</label>
                <div className="input-group">
                  <span className="country-code">+1</span>
                  <img
                    className="flag"
                    alt="Flag"
                    src={twemojiFlagPuertoRico}
                  />
                  <img
                    className="flag"
                    alt="Flag"
                    src={twemojiFlagPuertoRico2}
                  />
                  <input
                    type="text"
                    id="mobileNumber"
                    placeholder="(787) 000 0000"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="dob">Date Of Birth*</label>
                <input
                  type="text"
                  id="dob"
                  placeholder="08-05-1998"
                  value={formData.dob}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Example1212@gmail.com"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmEmail">Confirm Email*</label>
                <input
                  type="email"
                  id="confirmEmail"
                  placeholder="Example1212@gmail.com"
                  value={formData.confirmEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="role">Role*</label>
                <select id="role" value={formData.role} onChange={handleChange}>
                  <option>Manager</option>
                  <option>Scout</option>
                  <option>Guardian</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="zipCode">Zip Code*</label>
                <input
                  type="text"
                  id="zipCode"
                  placeholder="000000"
                  value={formData.zipCode}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password*</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Admin@123$"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password*</label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Admin@123$"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="addressLine1">Address Line 1*</label>
                <input
                  type="text"
                  id="addressLine1"
                  placeholder="House no., Building, Street"
                  value={formData.addressLine1}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="addressLine2">Address Line 2*</label>
                <input
                  type="text"
                  id="addressLine2"
                  placeholder="Landmark"
                  value={formData.addressLine2}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  id="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="state">State*</label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country*</label>
                <input
                  type="text"
                  id="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-actions">
              <button
                type="button"
                className="btn-back"
                onClick={() => setNewUser(!newUser)}
              >
                Back
              </button>
              <button type="submit" className="btn-create">
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>User Created</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>The user has been created successfully.</Text>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" onClick={closeModal}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
};
