import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Stack,
  Flex,
  Text,
  Divider,
  Grid,
  GridItem,
  Image,
  List,
  ListItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Progress,
  Spinner,
} from '@chakra-ui/react';
import { FaUser, FaGraduationCap, FaMapMarkerAlt, FaBaseballBall } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom'; // Importamos useParams
import axios from '../api/axiosConfig';
import WizardButton from '../components/Shared/WizardButton';
import { EditPlayerFormContext } from '../context/EditPlayerFormContext';

interface UpgradePlayerStep3Props {
  handlePreviousStep: () => void;
}

const UpgradePlayerStep3: React.FC<UpgradePlayerStep3Props> = ({ handlePreviousStep }) => {
  const navigate = useNavigate();
  const { id: playerId } = useParams<{ id: string }>(); // Obtenemos el playerId desde la URL
  const [guardianEmail, setGuardianEmail] = useState<string | null>(null);
  const { formData } = useContext(EditPlayerFormContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [timeLeft, setTimeLeft] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const storedGuardian = localStorage.getItem('user');
    if (storedGuardian) {
      const parsedGuardian = JSON.parse(storedGuardian);
      setGuardianEmail(parsedGuardian.email);
    } else {
      console.error('No guardian found in local storage');
    }
  }, []);

  const openConfirmationModal = () => {
    onOpen();
  };

  const confirmSubmit = async () => {
    if (isSubmitting || !guardianEmail) return;

    setIsSubmitting(true);

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        alert('No access token found. Please log in again.');
        setIsSubmitting(false);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      // Verificar que playerId esté disponible
      if (!playerId) {
        alert('Player ID not found in URL. Please try again.');
        setIsSubmitting(false);
        return;
      }


      const preparedFormData = {
        ...formData,
        aimingColleges: Array.isArray(formData.aimingColleges)
          ? formData.aimingColleges.join(', ')
          : formData.aimingColleges,
        playingPositions: Array.isArray(formData.playingPositions)
          ? formData.playingPositions.join(', ')
          : formData.playingPositions,
      };

      const payload = {
        formData: preparedFormData,
        guardianEmail,
      };

      // Realizar solicitud PUT a /upgrade/:id
      const response = await axios.put(`players/upgrade/${playerId}`, payload, config);

      if (response.data.success) {
        startTimer();
      } else {
        alert('Error updating the player. Please try again.');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error updating player:', error);
      alert('Error updating the player. Please try again.');
      setIsSubmitting(false);
    }
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current!);
          handleGoToRoster();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleGoToRoster = () => {
    navigate('/my-roster');
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current!);
  }, []);

  return (
    <>
      <Box bg="white" p={6} borderRadius="md" boxShadow="md" maxW="100%" mx="auto">
        <Grid templateColumns={{ base: '1fr', md: '1fr 1fr 1fr' }} gap={6}>
          {/* Sección Personal */}
          <GridItem colSpan={1}>
            <Stack spacing={6}>
              <Box>
                <HStack spacing={2} mb={2}>
                  <FaUser size={20} color="#09264a" aria-label="Personal" />
                  <Text fontSize="xl" fontWeight="bold">Personal</Text>
                </HStack>
                <Divider mb={4} />
                <Stack spacing={2}>
                  <Text><strong>First Name:</strong> {formData.firstName}</Text>
                  <Text><strong>Initial:</strong> {formData.initial}</Text>
                  <Text><strong>Last Name:</strong> {formData.lastName}</Text>
                  <Text><strong>Maiden Name:</strong> {formData.maidenName}</Text>
                  <Text><strong>Date of Birth:</strong> {formData.dateOfBirth}</Text>
                  <Text><strong>Last 4 SSN:</strong> {formData.last4SN}</Text>
                </Stack>
              </Box>
              <Box>
                <HStack spacing={2} mb={2}>
                  <FaGraduationCap size={20} color="#09264a" aria-label="Educational" />
                  <Text fontSize="xl" fontWeight="bold">Educational</Text>
                </HStack>
                <Divider mb={4} />
                <Stack spacing={2}>
                  <Text><strong>Graduation Year:</strong> {formData.graduationYear}</Text>
                  <Text><strong>Current School:</strong> {formData.currentSchool}</Text>
                  <Text>
                    <strong>Aiming Colleges:</strong>{' '}
                    {Array.isArray(formData.aimingColleges)
                      ? formData.aimingColleges.join(', ')
                      : formData.aimingColleges}
                  </Text>
                </Stack>
              </Box>
            </Stack>
          </GridItem>

          {/* Sección Ubicación y Juego */}
          <GridItem colSpan={1}>
            <Stack spacing={6}>
              <Box>
                <HStack spacing={2} mb={2}>
                  <FaMapMarkerAlt size={20} color="#09264a" aria-label="Location" />
                  <Text fontSize="xl" fontWeight="bold">Location</Text>
                </HStack>
                <Divider mb={4} />
                <Stack spacing={2}>
                  <Text><strong>Country:</strong> {formData.country}</Text>
                  <Text><strong>State:</strong> {formData.state}</Text>
                  <Text><strong>City:</strong> {formData.city}</Text>
                </Stack>
              </Box>
              <Box>
                <HStack spacing={2} mb={2}>
                  <FaBaseballBall size={20} color="#09264a" aria-label="Game" />
                  <Text fontSize="xl" fontWeight="bold">Game</Text>
                </HStack>
                <Divider mb={4} />
                <Stack spacing={2}>
                  <Text><strong>Weight (lbs):</strong> {formData.weight}</Text>
                  <Text><strong>Height (ft & in):</strong> {formData.height}</Text>
                  <Text>
                    <strong>Playing Positions:</strong>{' '}
                    {Array.isArray(formData.playingPositions)
                      ? formData.playingPositions.join(', ')
                      : formData.playingPositions}
                  </Text>
                  <Text><strong>Uniform Number:</strong> {formData.uniformNumber}</Text>
                  <Text><strong>Throwing Hand:</strong> {formData.throwingHand}</Text>
                  <Text><strong>Batting Hand:</strong> {formData.battingHand}</Text>
                </Stack>
              </Box>
            </Stack>
          </GridItem>

          {/* Sección Plan Seleccionado */}
          <GridItem colSpan={1}>
            <Stack spacing={6}>
              {formData.selectedPlan === 'insurance' && (
                <Box borderWidth="1px" borderRadius="md" p={4} boxShadow="sm" textAlign="center">
                  <Image
                    src="/images/Multinational_Logo_2022.png"
                    alt="Insurance Plan Logo"
                    mx="auto"
                    mb={4}
                    boxSize="100px"
                    objectFit="contain"
                  />
                  <Text fontSize="lg" fontWeight="bold">Insurance Plan</Text>
                  <Text mt={2}>Can insure player for the following:</Text>
                  <List spacing={1} my={2} textAlign="left">
                    <ListItem>- Career Ending Injuries</ListItem>
                    <ListItem>- Weather Calamities</ListItem>
                    <ListItem>- Loss of Parent</ListItem>
                    <ListItem>- Stolen Equipment</ListItem>
                    <ListItem>- Reimbursement Of Sport Trips</ListItem>
                  </List>
                  <Text fontWeight="bold" fontSize="lg">$250.00 per year</Text>
                </Box>
              )}
              {formData.selectedPlan === 'noInsurance' && (
                <Box borderWidth="1px" borderRadius="md" p={4} boxShadow="sm" textAlign="center">
                  <Text fontSize="lg" fontWeight="bold">No Insurance Plan</Text>
                  <Text mt={2}>You can choose to join the plan in the future.</Text>
                  <Text fontWeight="bold" fontSize="lg">No Cost.</Text>
                </Box>
              )}
            </Stack>
          </GridItem>
        </Grid>

        <Flex justify="flex-end" mt={8} gap={4}>
          <WizardButton onClick={handlePreviousStep} variant="back">Back</WizardButton>
          <WizardButton type="button" variant="next" onClick={openConfirmationModal}>Submit</WizardButton>
        </Flex>
      </Box>

      {/* Modal de confirmación */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Confirm Player Update</ModalHeader>
          <Box display="flex" justifyContent="center" mt={2}>
            <Image src="/images/confirmation.png" alt="Baseball Player Icon" boxSize="80px" />
          </Box>
          <ModalBody textAlign="center" mt={4}>
            <Text fontSize="lg">
              Are you sure you want to update{' '}
              <strong>{`${formData.firstName} ${formData.lastName}`}</strong>?
            </Text>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="flex-end" gap={4}>
            <WizardButton variant="cancel" onClick={onClose}>Cancel</WizardButton>
            <WizardButton
              variant="registrate"
              onClick={confirmSubmit}
              disabled={isSubmitting}
              _hover={{ boxShadow: 'md' }}
            >
              {isSubmitting ? <Spinner size="sm" color="white" /> : 'Confirm'}
            </WizardButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpgradePlayerStep3;
